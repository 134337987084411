export enum MemberStatus {
    Active = 'ACTIVE',
    DeletionPending = 'DELETION_PENDING',
    Deleted = 'DELETED',
    Pending = 'PENDING',
}

export enum ProfileStatus {
    Active = 'ACTIVE',
    PendingDeletion = 'PENDING_DELETION',
    Deleted = 'DELETED',
    Pending = 'PENDING',
}

export enum MemberType {
    Sub = 'SUB',
    Head = 'HEAD',
}

export type Member = {
    id: string;
    externalMemberId: string;
    type: MemberType;
    status: MemberStatus;
    loyaltyProfile: {
        names: {
            firstName: string;
            lastName: string;
            middleName: string;
            lang: string;
            prefix: string;
        }[];
    };
};

export type LoyaltyResponseData = {
    profile: {
        balancesContainer: {
            availablePoints: number;
            type: string;
            value: number;
        }[];
        membership?: {
            externalId: string;
            id: string;
            members: Member[];
        };
    };
};

export type MemberNames = {
    firstName: string;
    lastName: string;
    middleName: string;
    lang: string;
    prefix: string;
};
