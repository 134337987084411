import React from 'react';
import { useSelector } from 'react-redux';
import { COOKIES } from 'consts/cookies';
import { useApi } from 'hooks';
import { cookieService } from 'services';
import { getSortedAlerts } from 'store/modules/alerts';

import { TechnicalMessage } from '@s7/ui-kit';

import { transformAlertsData } from './helpers';

const halfYearSeconds = 60 * 60 * 24 * (365 / 2);

const TechnicalMessageContainer = () => {
    const {
        modules: {
            alerts: { loading },
        },
    } = useApi(['alerts']);

    const alerts = useSelector(getSortedAlerts);

    const handleCloseAlert = ({ id }) => {
        const viewedAlertIds = cookieService.get(COOKIES.ALERTS) || [];

        cookieService.set(COOKIES.ALERTS, [...viewedAlertIds, id], {
            maxAge: halfYearSeconds,
        });
    };

    return (
        !loading &&
        alerts.length > 0 && (
            <TechnicalMessage
                alerts={transformAlertsData(alerts)}
                align="center"
                onClose={handleCloseAlert}
            />
        )
    );
};

export default TechnicalMessageContainer;
