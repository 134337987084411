import React from 'react';
import { arrayOf, node, oneOf, oneOfType, shape, string } from 'prop-types';
import { Dictionary } from 'shared';

import cx from './Breadcrumbs.sss';

import Link from '../Link';

const commonBreadcrumbs = [
    {
        external: true,
        href: 'https://www.s7.ru/',
        title: <Dictionary text="com.breadcrumbs.main" />,
    },
    {
        href: '/',
        title: <Dictionary text="com.breadcrumbs.profile" />,
    },
];

const Breadcrumbs = ({ items, background }) => (
    <div
        className={cx('root', {
            [`root_${background}`]: background,
        })}
    >
        {commonBreadcrumbs
            .concat(items)
            .map(({ title, href, external }, key) => {
                const LinkComponent = external ? 'a' : Link;
                const externalProps = external
                    ? {
                          rel: 'noopener noreferrer',
                          target: '_blank',
                      }
                    : {};

                return (
                    <span className={cx('item')} {...{ key }}>
                        <LinkComponent
                            className={cx('link')}
                            href={href}
                            {...externalProps}
                        >
                            {title}
                        </LinkComponent>
                    </span>
                );
            })}
    </div>
);

Breadcrumbs.propTypes = {
    background: oneOf(['green', 'G50', 'N30']),
    items: arrayOf(
        shape({
            href: string,
            title: oneOfType([string, node]).isRequired,
        }),
    ),
};

Breadcrumbs.defaultProps = {
    items: [],
};

export default React.memo(Breadcrumbs);
