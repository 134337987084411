import React, { MouseEventHandler, useMemo } from 'react';
import { MEDIA_URL } from 'api/config/url';
import { Dictionary } from 'shared';
import { LoyaltyLevel } from 'src/shared/types/loyalty-level';
import { normalizeName, noun, numberWithSpaces } from 'utils/format';

import { ActionsPlusLinear, ArrowGrowth } from '@s7/icons';
import { Button, Typography } from '@s7/ui';

import { useUserMediaInfoStyles } from './styles';

import { UNITS_MEDIA_MILES, UNITS_MILES } from '../../consts';
import { useBreakpoint } from '../../hooks';
import { SkeletonLoaderUserMediaInfo } from '../../ui-kit/atoms/SkeletonLoader';
import Avatar from '../Avatar';

interface UserMediaInfoProps {
    avatarError: string;
    businessUpgrade: number;
    customerValue: string;
    deleteAvatar: () => Record<string, unknown>;
    getTestId: (a: string) => Record<string, unknown>;
    flights: number;
    imgURL: string;
    isAvatarDeleting: boolean;
    isAvatarSaving: boolean;
    isDoctor: boolean;
    isEnName: boolean;
    isLoyaltyInitialized: boolean;
    isPublic: boolean;
    loyalLevel: LoyaltyLevel;
    loyalLink: string;
    membershipID: string;
    miles: number;
    milesBonus: number;
    milesStatus: number;
    name: string;
    onOpenPriorityCard: MouseEventHandler<HTMLButtonElement>;
    resetAvatarError: () => Record<string, unknown>;
    saveAvatar: () => Record<string, unknown>;
    surname: string;
}

interface RenderInfoColProps {
    count: number;
    id: string;
    units: JSX.Element[];
}

const WYSIWYG_URL = `${MEDIA_URL}editor/blogs/create`;

const UserMediaInfo = ({
    avatarError,
    customerValue,
    deleteAvatar,
    getTestId,
    imgURL,
    isAvatarDeleting,
    isAvatarSaving,
    isDoctor,
    isLoyaltyInitialized,
    isPublic,
    miles,
    milesBonus,
    name,
    resetAvatarError,
    saveAvatar,
    surname,
}: UserMediaInfoProps): JSX.Element => {
    const [classes, cx] = useUserMediaInfoStyles();
    const isMobile = useBreakpoint().only('xs');

    const isFullUser = customerValue === 'FULL';

    const renderInfoCol = ({
        count,
        id,
        units,
    }: RenderInfoColProps): JSX.Element => (
        <div key={id} className={classes.col}>
            <div className={classes.count}>
                <Typography color="white.100" size="sm1" variant={'heading'}>
                    {numberWithSpaces(count)}
                </Typography>
            </div>

            <Typography
                color="white.100"
                size={isMobile ? undefined : 'xs1'}
                variant={'text'}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    noun(count, units)
                }
            </Typography>
        </div>
    );

    const info = useMemo(
        () =>
            [
                {
                    count: milesBonus,
                    id: 'milesBonus',
                    units: UNITS_MEDIA_MILES,
                },
            ].filter(infoObject => infoObject.count !== null),
        [milesBonus],
    );

    const renderUserInfo = (): null | JSX.Element => {
        if (isPublic) {
            return null;
        }

        return !isLoyaltyInitialized && isFullUser ? (
            <div className={classes.infoLoader}>
                <SkeletonLoaderUserMediaInfo />
            </div>
        ) : (
            <div className={classes.row}>
                {isMobile || info.map(renderInfoCol)}
                {isMobile &&
                    renderInfoCol({
                        count: miles,
                        id: 'miles',
                        units: UNITS_MILES,
                    })}
            </div>
        );
    };

    const normalizedName =
        !isPublic && name
            ? normalizeName(name, {
                  withSpaces: true,
              })
            : name;

    const nameText =
        name && surname
            ? `${normalizedName} ${surname[0]}.`
            : normalizedName || '—';

    return (
        <div className={classes.root}>
            <div
                className={cx(classes.infoRoot, {
                    [classes.isPublic]: isPublic,
                })}
            >
                <div className={classes.avatar}>
                    <Avatar
                        {...{
                            avatarError,
                            deleteAvatar,
                            imgURL,
                            isAvatarDeleting,
                            isAvatarSaving,
                            isDoctor,
                            isPublic,
                            name:
                                name && surname
                                    ? `${name[0]}${surname[0]}`
                                    : '',
                            resetAvatarError,
                            saveAvatar,
                            size: 'small',
                        }}
                    />
                </div>

                <div className={classes.info}>
                    <div className={classes.name}>
                        <Typography
                            color="white.100"
                            size="md1"
                            variant={'heading'}
                            {...getTestId('userInfoName')}
                        >
                            {nameText}
                        </Typography>
                    </div>

                    {renderUserInfo()}
                </div>
            </div>
            <div className={classes.buttonsContainers}>
                <Button
                    className={cx(classes.buttonClass, {
                        [classes.iconButton]: isMobile,
                    })}
                    classNames={{
                        rightIcon: isMobile && classes.iconButtonRightSide,
                    }}
                    as="a"
                    href={WYSIWYG_URL}
                    rightSide={<ActionsPlusLinear />}
                >
                    {!isMobile && (
                        <Dictionary text="com.media.user.write-article" />
                    )}
                </Button>
                <Button
                    as="a"
                    className={classes.buttonClass}
                    href={MEDIA_URL}
                    rightSide={<ArrowGrowth />}
                >
                    <Dictionary text="com.media.user.go-to-media" />
                </Button>
            </div>
        </div>
    );
};

export default React.memo(UserMediaInfo);
