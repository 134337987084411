import React, { ReactNode } from 'react';

import { Box } from '@s7/ui';

import { useLabelStyles } from './styles';

type LabelProps = {
    icon: ReactNode;
    className?: string;
    active?: boolean;
};

export const Label = ({
    icon,
    className,
    active = true,
}: LabelProps): JSX.Element => {
    const [classes, cx] = useLabelStyles();

    return (
        <Box
            className={cx(className, classes.label, {
                [classes.disabled]: !active,
            })}
        >
            {icon}
        </Box>
    );
};
