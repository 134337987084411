import React, { forwardRef, memo, useEffect, useState } from 'react';
import { elementType, func, node, string } from 'prop-types';

import cx from './RippleElement.sss';

const defaultCoord = { x: -1, y: -1 };

const RippleElement = forwardRef(function RippleElement(
    { as: Component, children, onMouseDown, className, ...props },
    ref,
) {
    const [coords, setCoords] = useState(defaultCoord);
    const [isRippling, setIsRippling] = useState(false);

    const handleMouseDown = e => {
        e.preventDefault();
        const { target, clientX, clientY } = e;
        const rect = target.getBoundingClientRect();

        setCoords({ x: clientX - rect.left, y: clientY - rect.top });

        if (onMouseDown) {
            onMouseDown(e);
        }
    };

    useEffect(() => {
        if (coords.x !== defaultCoord.x && coords.y !== defaultCoord.y) {
            setIsRippling(true);
            setTimeout(() => setIsRippling(false), 500);
        } else {
            setIsRippling(false);
        }
    }, [coords]);

    useEffect(() => {
        if (!isRippling) {
            setCoords(defaultCoord);
        }
    }, [isRippling]);

    return (
        <Component
            ref={ref}
            className={cx('root', className)}
            onMouseDown={handleMouseDown}
            {...props}
        >
            {children}
            {isRippling && (
                <span
                    style={{
                        left: coords.x,
                        top: coords.y,
                    }}
                    className={cx('ripple')}
                />
            )}
        </Component>
    );
});

RippleElement.propTypes = {
    as: elementType,
    children: node,
    className: string,
    onMouseDown: func,
};

RippleElement.defaultProps = {
    as: 'a',
};

const RippleElementMemoized = memo(RippleElement);

export { RippleElementMemoized as RippleElement };
