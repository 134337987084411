import React, { useEffect } from 'react';
import { useAddThisLoaded, useWindowSize } from 'hooks';
import { bool, func, object, shape, string } from 'prop-types';
import { Dictionary } from 'shared';

import { Button, CardLabel, Text } from '@s7/ui-kit';
import { CloseLinear, LockFilled } from '@s7/ui-kit/icons';

import cx from './Achievements.sss';

import { ModalWrapper } from '../Modal';

export const AchievementModal = React.memo(function AchievementModal({
    achievedRightNow = false,
    action,
    badgeStyles,
    closeModal,
    code,
    description,
    image,
    inactive,
    isActive,
    isModalOpen,
    isMyPublic,
    isNew,
    name,
}) {
    const { isMobile } = useWindowSize();
    const isAddThisLoaded = useAddThisLoaded();

    useEffect(() => {
        if (
            isAddThisLoaded &&
            isModalOpen &&
            isActive &&
            isMyPublic &&
            window.addthis.layers.refresh
        ) {
            window.addthis.layers.refresh();
        }
    }, [isModalOpen, isActive, isMyPublic, isAddThisLoaded]);

    return (
        <ModalWrapper
            id={`achievement-${code}`}
            isOpen={isModalOpen}
            overlayClassName={cx('modal-overlay')}
            widthAuto
            onClose={closeModal}
        >
            <div className={cx('modal')}>
                {isNew && (
                    <CardLabel color="O600" position="topLeft">
                        {achievedRightNow ? (
                            <Dictionary text="com.global.new-achievement" />
                        ) : (
                            'New!'
                        )}
                    </CardLabel>
                )}
                <div className={cx('modal-top')}>
                    <div className={cx('modal-badge')} style={badgeStyles}>
                        <img alt="" className={cx('modal-img')} src={image} />
                        {inactive && (
                            <div className={cx('lock', 'modal-lock')}>
                                <LockFilled height="40" width="40" block />
                            </div>
                        )}
                        <button
                            className={cx('modal-close', {
                                'modal-close-inactive': inactive,
                            })}
                            type="button"
                            onClick={closeModal}
                        >
                            <CloseLinear />
                        </button>
                    </div>
                    <div className={cx('modal-name')}>{name}</div>
                    <div className={cx('modal-description')}>
                        <Text
                            alignment="center"
                            size={isMobile ? undefined : 'l'}
                        >
                            {description}
                        </Text>
                    </div>
                </div>
                <div className={cx('modal-bottom')}>
                    {action && (
                        <div className={cx('modal-know-more')}>
                            <Button
                                as="a"
                                href={action.link.url}
                                rel="noopenner noreferrer"
                                size="large"
                                target="_blank"
                                theme="b2c"
                                block
                            >
                                {action.text}
                            </Button>
                        </div>
                    )}
                    {isActive && isMyPublic && (
                        <div className={cx('modal-social-buttons')}>
                            <div
                                className="addthis_inline_share_toolbox"
                                data-url={`https://www.s7.ru/ru/badges/${code}/?utm_medium=social&utm_campaign=gamification`}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ModalWrapper>
    );
});

AchievementModal.propTypes = {
    achievedRightNow: bool,
    action: shape({
        link: shape({
            inner: bool,
            url: string,
        }),
        text: string,
    }),
    badgeStyles: object,
    closeModal: func,
    code: string,
    description: string,
    image: string,
    inactive: bool,
    isActive: bool,
    isModalOpen: bool,
    isMyPublic: bool,
    isNew: bool,
    name: string,
};
