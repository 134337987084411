import React from 'react';
import BarcodeBase from 'react-barcode';
import { bool, node, number, string } from 'prop-types';
import { Dictionary } from 'shared';

import { Text } from '@s7/ui-kit';

import cx from './Barcode.sss';

const TEXTS = {
    title: <Dictionary text="com.title.barcode" />,
};

export const Barcode = props => {
    const { value, displayValue, height, width, withTitle, ...rest } = props;

    return (
        <div className={cx('root')}>
            {withTitle && (
                <div className={cx('title')}>
                    <Text as="span" color="N100" size="s">
                        {TEXTS.title}
                    </Text>
                </div>
            )}
            <BarcodeBase
                {...{ displayValue, height, value, width }}
                {...rest}
            />
        </div>
    );
};

Barcode.propTypes = {
    displayValue: bool,
    height: number,
    title: node,
    value: string,
    width: number,
    withTitle: bool,
};

Barcode.defaultProps = {
    displayValue: false,
    height: 60,
    width: 1.1,
    withTitle: true,
};
