import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chat from 'containers/Chat';
import { useWindowSize } from 'hooks';
import { ErrorBoundary } from 'shared';
import { MEDIA_API_URL } from 'src/shared/config';
import { analytics } from 'src/shared/lib/analitics-manager';
import { getLocale } from 'store/modules/languages';
import {
    getIsFooterChatWidgetOpen,
    setFooterChatWidgetOpen,
} from 'store/modules/shared';

import { clickToCall } from '@s7/click-to-call';
import { FooterWidget } from '@s7/footer';
import { Box, Skeleton } from '@s7/ui';

import { CallItemProps } from '../type';

export const Footer = () => {
    const { isMobile } = useWindowSize();

    const dispatch = useDispatch();

    const isChatOpen = useSelector(getIsFooterChatWidgetOpen);
    const locale = useSelector(getLocale);

    const setChatState = (value: boolean) => {
        dispatch(
            (setFooterChatWidgetOpen as (value: boolean) => unknown)(value),
        );
    };

    const callCenterClick = (item: CallItemProps) => {
        const { variant } = item;
        if (variant && variant === 'call') {
            clickToCall();
        }

        if (variant && variant === 'chat') {
            setChatState(true);
        }
    };
    return (
        <Box
            sx={theme => ({
                backgroundColor: theme.palette.background.back,
            })}
        >
            <FooterWidget
                handlers={{
                    callCenter: {
                        onActionItemClick: function (item) {
                            callCenterClick(item);
                        },
                    },
                }}
                service={{
                    url: MEDIA_API_URL,
                    code: 'myprofile',
                    device: isMobile ? 'MOBILE' : 'DESKTOP',
                    lang: locale,
                    cache: true,
                }}
                analytics={analytics}
                renderSkeleton={() => <Skeleton height={'90vh'} />}
            />
            <ErrorBoundary>
                <React.Suspense fallback={<div />}>
                    <Chat
                        minimizeChatHandle={() => {
                            setChatState(false);
                        }}
                        isChatOpen={isChatOpen}
                        isExperiment
                    />
                </React.Suspense>
            </ErrorBoundary>
        </Box>
    );
};
