import { LabelType } from 'src/shared/types/label';

const getEventResult = (name: string) => {
    switch (name) {
        case 'tinkoff':
            return 'go_to_landing';
        case 'yandex':
            return 'go_to_subscriptions';
        default:
            return null;
    }
};

export const getItemClickEvent = (label: LabelType) => ({
    event_name: 'click_cta',
    flow: 'your_options',
    item_variant: `${label.name}_${label.active}`,
    result: getEventResult(label.name),
    step: label.name,
});
