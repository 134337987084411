import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TechnicalMessage } from 'components/TechnicalMessage';
import Breadcrumbs from 'containers/Breadcrumbs';
import { S7PriorityCard } from 'containers/S7PriorityCard';
import {
    useAppContext,
    useGoogleAnalyticContext,
    useStyleVariantContext,
} from 'contexts';
import { arrayOf, element, object, oneOf, oneOfType, string } from 'prop-types';
import {
    clearNewAchievement,
    getNewAchievement,
} from 'store/modules/statistics';
import { RULE_ALERTS } from 'utils/allowFeature';

import cx from './PageContainer.sss';

import { AchievementModal } from '../Achievements';

const PageContainer = ({ bgColor, children, breadcrumbs, className }) => {
    const dispatch = useDispatch();
    const achievement = useSelector(getNewAchievement);
    const { sendUserInfoEvent } = useGoogleAnalyticContext();
    const { inApp } = useAppContext();
    const { variant } = useStyleVariantContext();

    const closeAchievementModal = useCallback(() => {
        dispatch(clearNewAchievement());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (window) {
            setTimeout(() => sendUserInfoEvent(), 500);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={cx(
                {
                    [`bg-color-${bgColor}`]: bgColor,
                    inApp,
                    root: variant === 'default',
                },
                className,
            )}
        >
            {RULE_ALERTS && <TechnicalMessage />}
            {breadcrumbs && (
                <div className={cx('breadcrumb')}>
                    <Breadcrumbs items={breadcrumbs} />
                </div>
            )}
            {children}

            <S7PriorityCard />

            <AchievementModal
                {...achievement}
                closeModal={closeAchievementModal}
                isModalOpen={Boolean(achievement)}
                achievedRightNow
            />
        </div>
    );
};

PageContainer.propTypes = {
    bgColor: oneOf(['light-green']),
    breadcrumbs: arrayOf(object),
    children: oneOfType([element, arrayOf(element)]),
    className: string,
};

export default PageContainer;
