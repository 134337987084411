import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { Dictionary } from 'shared';

import { AchievementItem } from './AchievementItem';
import cx from './Achievements.sss';

const stub = new Array(7).fill(null).map((value, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <AchievementItem key={`achievement-stub-${index}`} stub />
));

const TEXTS = {
    country: <Dictionary text="com.statistics.achievements.country" />,
    distance: <Dictionary text="com.statistics.achievements.distance" />,
    opened: <Dictionary text="com.statistics.achievements.opened" />,
    participant: <Dictionary text="com.statistics.achievements.participant" />,
    profile: <Dictionary text="com.statistics.achievements.profile" />,
    regions: <Dictionary text="com.statistics.achievements.regions" />,
};

export const Achievements = React.memo(function Achievements({
    groups,
    isPublic,
    isMyPublic,
}) {
    return (
        <div className={cx('root', { isPublic })}>
            {groups.map(
                ({ achievements, code: groupCode, open, name: groupName }) => (
                    <div key={groupCode} className={cx('group')}>
                        {groupCode && (
                            <>
                                <div className={cx('title')}>
                                    {groupName ||
                                        TEXTS[groupCode.toLowerCase()]}
                                </div>
                                <div className={cx('subtitle')}>
                                    {TEXTS.opened} {open}
                                </div>
                            </>
                        )}
                        <div className={cx('list')}>
                            <div className={cx('list-inner')}>
                                {achievements
                                    .map(
                                        ({
                                            achievedAt,
                                            action,
                                            backgroundColors,
                                            buttonTitle,
                                            code,
                                            description,
                                            image,
                                            isNew,
                                            name,
                                            status,
                                            thumbnail,
                                        }) => (
                                            <AchievementItem
                                                key={name}
                                                {...{
                                                    achievedAt,
                                                    action,
                                                    backgroundColors,
                                                    buttonTitle,
                                                    code,
                                                    description,
                                                    image,
                                                    isActive:
                                                        status === 'ACTIVE',
                                                    isMyPublic,
                                                    isNew,
                                                    name,
                                                    thumbnail,
                                                }}
                                            />
                                        ),
                                    )
                                    .concat(stub)}
                            </div>
                        </div>
                    </div>
                ),
            )}
        </div>
    );
});

Achievements.propTypes = {
    groups: arrayOf(object),
    isMyPublic: bool,
    isPublic: bool,
};

Achievements.defaultProps = {
    isMyPublic: true,
};
