import { pathOr, propOr } from 'ramda';
import { createAction, createReducer } from 'redux-act';

const initialState = {
    achievements: [],
    data: {},
    isError: false,
    isInitialized: false,
    isLoading: false,
    isMediaError: false,
    isMediaInitialized: false,
    isMediaLoading: false,
    isNearestFlightError: false,
    isNearestFlightInitialized: false,
    isNearestFlightLoading: false,
    medias: null,
    nearestFlight: {},
    profileId: '',
    statistics: null,
};

export const fetchPublicProfile = createAction(
    'publicProfile/fetchPublicProfile',
);
export const fetchPublicProfileSuccess = createAction(
    'publicProfile/fetchPublicProfileSuccess',
);
export const fetchPublicProfileFailure = createAction(
    'publicProfile/fetchPublicProfileFailure',
);

export const fetchMedia = createAction('fetchMedia/fetchMedia');
export const fetchMediaSuccess = createAction('fetchMedia/fetchMediaSuccess');
export const fetchMediaFailure = createAction('fetchMedia/fetchMediaFailure');

export const fetchNearestFlight = createAction('fetchMedia/fetchNearestFlight');
export const fetchNearestFlightSuccess = createAction(
    'fetchMedia/fetchNearestFlightSuccess',
);
export const fetchNearestFlightFailure = createAction(
    'fetchMedia/fetchNearestFlightFailure',
);

const handleFetchPublicProfile = state => ({
    ...state,
    isInitialized: false,
    isLoading: true,
});

const handleFetchPublicProfileSuccess = (state, data) => ({
    ...state,
    achievements: pathOr(null, ['achievements'], data),
    data: propOr({}, 'publicProfile', data),
    isError: false,
    isInitialized: true,
    isLoading: false,
    profileId: pathOr({}, ['profileId'], data),
    statistics: pathOr(null, ['statistics', 'statistics', 0], data),
});

const handleFetchPublicProfileFailure = (state, profileId) => ({
    ...state,
    isError: true,
    isInitialized: true,
    isLoading: false,
    profileId,
});

const handleFetchMedia = state => ({
    ...state,
    isMediaInitialized: false,
    isMediaLoading: true,
});

const handleFetchMediaSuccess = (state, medias) => ({
    ...state,
    isMediaError: false,
    isMediaInitialized: true,
    isMediaLoading: false,
    medias,
});

const handleFetchMediaFailure = state => ({
    ...state,
    isMediaError: true,
    isMediaInitialized: true,
    isMediaLoading: false,
});

const handleFetchNearestFlight = state => ({
    ...state,
    isNearestFlightInitialized: false,
    isNearestFlightLoading: true,
});

const handleFetchNearestFlightSuccess = (state, nearestFlight) => ({
    ...state,
    isNearestFlightError: false,
    isNearestFlightInitialized: true,
    isNearestFlightLoading: false,
    nearestFlight,
});

const handleFetchNearestFlightFailure = state => ({
    ...state,
    isNearestFlightError: true,
    isNearestFlightInitialized: true,
    isNearestFlightLoading: false,
});

export default createReducer(
    {
        [fetchMedia]: handleFetchMedia,
        [fetchMediaFailure]: handleFetchMediaFailure,
        [fetchMediaSuccess]: handleFetchMediaSuccess,
        [fetchNearestFlight]: handleFetchNearestFlight,
        [fetchNearestFlightFailure]: handleFetchNearestFlightFailure,
        [fetchNearestFlightSuccess]: handleFetchNearestFlightSuccess,
        [fetchPublicProfile]: handleFetchPublicProfile,
        [fetchPublicProfileFailure]: handleFetchPublicProfileFailure,
        [fetchPublicProfileSuccess]: handleFetchPublicProfileSuccess,
    },
    initialState,
);

export * from './selectors';
