import { createStyles } from '@s7/ui';

export const useModalItemStyles = createStyles((theme, { active }) => ({
    chevron: {
        alignSelf: 'center',
        marginLeft: 12,
        [theme.fn.down('sm1')]: {
            display: 'none',
        },
    },
    description: {
        color: theme.invariants.neutral['100'],
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: 8,
    },
    item: {
        display: 'flex',
        padding: '24px 0',
        '> div:first-of-type': {
            height: 32,
            minWidth: 32,
            width: 32,
        },
    },
    title: {
        color: theme.invariants.neutral[active ? '900' : '100'],
    },
    wrapper: {
        padding: '0 12px',
    },
}));
