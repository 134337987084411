import React, { ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';

type LinkProps = NextLinkProps & {
    activeClassName?: string;
    className: string;
    children: ReactNode;
};

const Link = React.memo(function Link({
    children,
    className,
    activeClassName,
    href,
    as,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
    ...props
}: LinkProps) {
    const router = useRouter();

    let classNames = className;

    if (router.pathname === href && activeClassName) {
        classNames = `${className || ''} ${activeClassName}`.trim();
    }

    return (
        <NextLink
            {...{
                as,
                href,
                passHref,
                prefetch,
                replace,
                scroll,
                shallow,
            }}
        >
            <a className={classNames} {...props}>
                {children}
            </a>
        </NextLink>
    );
});

export default Link;
