import React, { memo } from 'react';
import Loadable from 'decorators/Loadable';
import { node } from 'prop-types';
import { compose } from 'ramda';
import {
    isError as isErrorLanguages,
    isLoading as isLoadingLanguages,
} from 'store/modules/languages';
import { getIsShortProfile } from 'store/modules/profile';
import { SkeletonLoaderProfilePopup } from 'ui-kit/atoms/SkeletonLoader';

const ProfilePopupLoadable = ({ children }) => <>{children}</>;

ProfilePopupLoadable.propTypes = { children: node.isRequired };

export default compose(
    Loadable({
        isError: state =>
            !(
                !state.profile.isError &&
                !state.countries.isError &&
                !isErrorLanguages(state)
            ),
        isLoading: state => {
            const isShortProfile = getIsShortProfile(state);

            return !(
                state.profile.isInitialized &&
                (isShortProfile || state.loyalty.isInitialized) &&
                !isLoadingLanguages(state)
            );
        },
        Loader: (state, { size, separate }) => (
            <SkeletonLoaderProfilePopup separate={separate} size={size} />
        ),
    }),
    memo,
)(ProfilePopupLoadable);
