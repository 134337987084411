export const PROFILE_ANCHORS = {
    b2b: 'b2b',
    cards: 'cards',
    contacts: 'contacts',
    documents: 'documents',
    email: 'email',
    family: 'family',
    groupAccount: 'groupAccount',
    personal: 'personal',
    pets: 'pets',
    phone: 'phone',
    QRCodes: 'qrcodes',
    travelers: 'travelers',
    visa: 'visa',
};

export const PROFILE_MODALS = {
    contactInformation: 'contact-information',
    editEmail: 'edit-email',
    editPhone: 'edit-phone',
};

export const PROFILE_ANCHORS_VALUES = Object.values(PROFILE_ANCHORS);
