import React from 'react';
import { LabelProps } from 'src/shared/types/label';
import { Label } from 'src/shared/ui/Label';

import { LogotypesTinkoff } from '@s7/icons';
import { packSX } from '@s7/ui';

import { useTinkoffLabelStyles } from './styles';

export const TinkoffLabel = ({
    active,
    iconSX,
    className,
}: LabelProps): JSX.Element => {
    const [classes, cx] = useTinkoffLabelStyles();

    return (
        <Label
            icon={
                <LogotypesTinkoff
                    sx={[
                        theme => ({
                            width: 12,
                            height: 12,
                            [theme.fn.up('sm1')]: {
                                width: 18,
                                height: 18,
                            },
                        }),
                        ...packSX(iconSX),
                    ]}
                />
            }
            active={active}
            className={cx(classes.tinkoff, className)}
        />
    );
};
