import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PageContainer from 'components/PageContainer';
import Breadcrumbs from 'containers/Breadcrumbs';
import Header from 'containers/Header';
import PublicProfileHeader from 'containers/PublicProfileHeader';
import { useApi, useLanguages } from 'hooks';
import { arrayOf, node, oneOf, shape, string } from 'prop-types';
import { Footer } from 'src/widgets/footer';
import { getIsPublicProfileError } from 'store/modules/publicProfile';

import cx from './PublicProfileLayout.sss';

const PublicProfileLayout = ({
    background,
    breadcrumbs,
    children,
    languages,
    modules,
    title,
}) => {
    useApi(modules);
    const dictionary = useLanguages(languages);
    const isError = useSelector(getIsPublicProfileError);

    return (
        <>
            <Helmet>
                <title>S7 Airlines | {dictionary.t(title)}</title>
            </Helmet>
            <Header />
            <PageContainer>
                <div
                    className={cx('background', {
                        [`background_${background}`]: background,
                    })}
                >
                    {breadcrumbs && (
                        <div className={cx('breadcrumbs')}>
                            <Breadcrumbs
                                background={background}
                                items={breadcrumbs}
                            />
                        </div>
                    )}
                    <div className={cx('content')}>
                        {isError || <PublicProfileHeader />}
                        {children}
                    </div>
                </div>
            </PageContainer>
            <Footer />
        </>
    );
};

PublicProfileLayout.propTypes = {
    background: oneOf(['green', 'G50']),
    breadcrumbs: arrayOf(
        shape({
            href: string,
            title: node,
        }),
    ),
    children: node,
    languages: arrayOf(string),
    modules: arrayOf(string),
    title: string,
};

export default PublicProfileLayout;
