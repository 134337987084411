import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Link from 'components/Link';
import Dictionary from 'shared/Dictionary';
import { getAvailableMilesOperations } from 'store/modules/loyalty';
import {
    getEmailConfirmed,
    getTelephoneConfirmed,
} from 'store/modules/profile';

import { Link as DSLink, NotificationBlock, Text } from '@s7/ui-kit';

import { PriorityStatus } from './PriorityStatus';

import { useContactNotificationText } from '../hooks/use-contact-notification-text';

type Props = {
    closeProfileInfo: () => void;
    children?: ReactNode;
};

const ExpirationNotify = () => {
    return (
        <Text>
            <Dictionary text="com.profile.expire.notification.text" />{' '}
            <DSLink as={Link} color="O600" href="/miles?action=expire">
                <Dictionary text="com.profile.expire.notification.link" />
            </DSLink>
        </Text>
    );
};

const ActivityStatusNotify = () => {
    return (
        <>
            <Text weight="semibold">
                <Dictionary text="com.profile.activate-status.notification.title" />
            </Text>
            <Text>
                <Dictionary text="com.profile.activate-status.notification.text" />
                <br />
                <DSLink
                    as={Link}
                    href="/miles?action=activateStatusNotification"
                    view="warning"
                >
                    <Dictionary text="com.profile.activate-status.notification.link" />
                </DSLink>
            </Text>
        </>
    );
};

type NotificationTemplateProps = {
    text: ReactNode;
};

const NotificationTemplate = ({ text }: NotificationTemplateProps) => {
    return (
        <NotificationBlock text={text}>
            <PriorityStatus />
        </NotificationBlock>
    );
};

export const Notification = ({
    closeProfileInfo,
    children,
}: Props): JSX.Element | null => {
    const phoneConfirmed = useSelector(getTelephoneConfirmed);
    const emailConfirmed = useSelector(getEmailConfirmed);
    const { isActivateStatus, isExpiration } = useSelector(
        getAvailableMilesOperations,
    );
    const contactNotificationText = useContactNotificationText();

    if (isActivateStatus) {
        return <NotificationTemplate text={<ActivityStatusNotify />} />;
    }

    if (isExpiration) {
        return <NotificationTemplate text={<ExpirationNotify />} />;
    }

    if (emailConfirmed && phoneConfirmed) {
        return <>{children}</>;
    }
    return (
        <NotificationTemplate
            text={
                <DSLink
                    as={Link}
                    href="/#contacts"
                    view="warning"
                    onClick={closeProfileInfo}
                >
                    <Dictionary text={contactNotificationText} />
                </DSLink>
            }
        />
    );
};
