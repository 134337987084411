import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'components/Header';
import { useApi } from 'hooks';
import { useRouter } from 'next/router';
import { SessionService } from 'services';
import { Dictionary } from 'shared';
import { useChangeLanguage } from 'src/features/language';
import { LANGUAGES } from 'src/shared/constants';
import { logout, openLoginModal } from 'store/modules/authentication';
import {
    getVisible as getVisibleLanguages,
    selectLocale,
    toggleVisible as toggleVisibleLanguage,
} from 'store/modules/languages';
import { getAvailableMilesOperations } from 'store/modules/loyalty';
import {
    fetch as fetchProfile,
    getAvatarPreviewLink,
} from 'store/modules/profile';
import { getActiveSubscription } from 'store/modules/promo';
import { getIsModalOpen } from 'store/modules/shared';
import { isDevelop, isQa, isRest } from 'utils/stages';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

const TITLES = {
    loginTitle: <Dictionary text="com.authorization.button.login" />,
    logoutTitle: <Dictionary text="com.header.menu.logout" />,
};

const getTestId = getTestIdGetter(dictKeys.HEADER);

const HeaderContainer = () => {
    useApi(['profile', 'loyalty']);
    const dispatch = useDispatch();
    const router = useRouter();

    const [headerAbsolute, setHeaderAbsolute] = useState(false);

    const isVisibleLanguages = useSelector(getVisibleLanguages);
    const isAuth = SessionService.isAuth();
    const language = useSelector(selectLocale);
    const { isActivateStatus } = useSelector(getAvailableMilesOperations);

    const avatarUrl = useSelector(getAvatarPreviewLink);
    const isModalOpen = useSelector(getIsModalOpen);

    const activeSubscription = useSelector(getActiveSubscription);

    const [openUserInfo, setOpenUserInfo] = useState(false);

    const onChangeLanguage = useChangeLanguage();

    const handleFetchProfile = useCallback(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const handleLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const handleOpenLogin = useMemo(
        () =>
            router.route !== '/login'
                ? () => dispatch(openLoginModal())
                : undefined,
        [dispatch, router.route],
    );

    const handleToggleLanguageVisibility = useMemo(
        () =>
            isDevelop || isRest
                ? () => dispatch(toggleVisibleLanguage())
                : undefined,
        [dispatch],
    );

    const handleToggleHeaderAbsolute = React.useMemo(
        () =>
            isDevelop || isRest || isQa
                ? () => setHeaderAbsolute(!headerAbsolute)
                : undefined,
        [headerAbsolute, setHeaderAbsolute],
    );

    return (
        <Header
            isLogin={!isAuth}
            languages={LANGUAGES}
            loginTitle={TITLES.loginTitle}
            logout={handleLogout}
            openLogin={handleOpenLogin}
            toggleHeaderAbsolute={handleToggleHeaderAbsolute}
            toggleVisibleLanguage={handleToggleLanguageVisibility}
            onFetchProfile={handleFetchProfile}
            {...{
                activeSubscription,
                avatarUrl,
                getTestId,
                headerAbsolute,
                isActivateStatus,
                isModalOpen,
                isVisibleLanguages,
                language,
                onChangeLanguage,
                openUserInfo,
                setOpenUserInfo,
            }}
        />
    );
};

export default HeaderContainer;
