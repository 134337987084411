import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAnalyticsContext } from 'contexts';
import { LabelsModal } from 'src/features/labels-modal';

import { ChevronChevronNormalRight } from '@s7/icons';
import { Box } from '@s7/ui';

import { getLabels } from '../../lib/get-labels';
import { labelsMap } from '../../lib/labels-map';

export const LabelsList = (): JSX.Element => {
    const { sendEvent } = useAnalyticsContext();

    const labels = useSelector(getLabels);

    const [modalVisible, setModalVisible] = useState(false);

    const toggleModalVisible = (): void => {
        if (!modalVisible) {
            sendEvent({
                event_name: 'click_cta',
                flow: 'navigation',
                result: 'go_to_options',
                step: 'your_options',
            });
        }

        setModalVisible(visible => !visible);
    };

    return (
        <>
            <Box
                sx={theme => ({
                    '& div:not(:first-of-type)': {
                        marginLeft: '-3px',
                        outline: `2px solid ${theme.invariants.white['100']}`,
                    },

                    alignItems: 'center',
                    borderRight: `1px solid ${theme.invariants.neutral['40']}`,
                    cursor: 'pointer',
                    display: 'flex',
                    paddingRight: 23,
                    [theme.fn.down('sm1')]: {
                        '& div': {
                            height: 24,
                            minWidth: 24,
                            width: 24,
                        },
                    },
                })}
                tabIndex={0}
                onClick={toggleModalVisible}
                onKeyDown={toggleModalVisible}
            >
                {labels.map(labelsMap)}
                <ChevronChevronNormalRight
                    sx={theme => ({
                        color: theme.invariants.neutral['100'],
                        [theme.fn.down('sm1')]: {
                            display: 'none',
                        },
                        marginLeft: 8,
                    })}
                />
            </Box>
            <LabelsModal
                labels={labels}
                toggleVisible={toggleModalVisible}
                visible={modalVisible}
            />
        </>
    );
};
