import React from 'react';
import { useAnalyticsContext } from 'contexts';
import { useRouter } from 'next/router';
import Dictionary from 'shared/Dictionary';
import { LabelType } from 'src/shared/types/label';

import { ChevronChevronNormalRight } from '@s7/icons';
import { Box, Link, Typography } from '@s7/ui';

import { useModalItemStyles } from './styles';

import { getItemClickEvent } from '../../lib/get-item-click-event';
import { ActiveLabelBadge } from '../ActiveLabelBadge';

type ModalItemProps = {
    label: LabelType;
    toggleVisible: () => void;
};

export const ModalItem = ({
    label,
    toggleVisible,
}: ModalItemProps): JSX.Element => {
    const { push } = useRouter();
    const { active, link, texts, Node, name } = label;

    const [classes] = useModalItemStyles({ active });
    const { sendEvent } = useAnalyticsContext();

    const onClickItem = async () => {
        sendEvent(getItemClickEvent(label));
        await push(link);
        toggleVisible();
    };

    return (
        <Link className={classes.item} underline="none" onClick={onClickItem}>
            <Node
                iconSX={
                    name === 'tinkoff'
                        ? theme => ({
                              width: 16,
                              height: 16,
                              [theme.fn.up('sm1')]: {
                                  width: 16,
                                  height: 16,
                              },
                          })
                        : undefined
                }
                active={active}
            />
            <Box className={classes.wrapper}>
                <Box className={classes.header}>
                    <Typography
                        className={classes.title}
                        size="lg1"
                        variant={'text'}
                        weight="semibold"
                    >
                        <Dictionary text={texts.title} />
                    </Typography>
                    {active && <ActiveLabelBadge />}
                </Box>
                <Typography className={classes.description} variant={'text'}>
                    <Dictionary text={texts.description} />
                </Typography>
            </Box>
            <ChevronChevronNormalRight
                className={classes.chevron}
                color="neutral"
            />
        </Link>
    );
};
