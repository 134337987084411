import React from 'react';
import { bool, number, string } from 'prop-types';
import QRCodeBase from 'qrcode.react';
import { Dictionary } from 'shared';

import { Text } from '@s7/ui-kit';

import cx from './QRCode.sss';

const TEXTS = {
    title: <Dictionary text="com.title.qr-code" />,
};

export const QRCode = props => {
    const { value, size, renderAs, withTitle, ...rest } = props;

    return (
        <div className={cx('root')} style={{ width: size }}>
            {withTitle && (
                <div className={cx('title')}>
                    <Text as="span" color="N100" size="s">
                        {TEXTS.title}
                    </Text>
                </div>
            )}

            <QRCodeBase {...{ renderAs, size, value }} {...rest} />
        </div>
    );
};

QRCode.propTypes = {
    renderAs: string,
    size: number,
    value: string,
    withTitle: bool,
};

QRCode.defaultProps = {
    renderAs: 'svg',
    size: 176,
    withTitle: true,
};
