import React from 'react';
import { func, node, oneOf, string } from 'prop-types';
import { Dictionary } from 'shared';
import Text from 'ui-kit/atoms/Text';

import { Button } from '@s7/ui-kit';

import cx from './MilesActionModal.sss';

import Icon from '../Icon';
import Modal from '../Modal';

const icons = {
    failure: <Icon color="red" icon="confirmed-n" />,
    success: '👌',
};

const buttonTextes = {
    failure: <Dictionary text="com.modal.button.failure" />,
    success: (
        <>
            <Dictionary text="com.modal.button.success" />!
        </>
    ),
};

const buttonThemes = {
    failure: 'secondary',
    success: 'b2c',
};

const buttonColors = {
    failure: 'black',
    success: 'white',
};

const MilesActionModal = React.memo(function MilesActionModal({
    type,
    title,
    description,
    onClose,
    id,
}) {
    return (
        <Modal id={id} centered onClose={onClose}>
            <div className={cx('root')}>
                <button className={cx('close')} type="button" onClick={onClose}>
                    <Icon color="gray" icon="close" />
                </button>
                <div className={cx('title')}>
                    <span className={cx('title-icon')}>{icons[type]}</span>
                    <Text size="subtitle" weight="bold">
                        {title}
                    </Text>
                </div>
                {description && (
                    <div className={cx('description')}>
                        <Text>{description}</Text>
                    </div>
                )}
                <div className={cx('action-button')}>
                    <Button theme={buttonThemes[type]} block onClick={onClose}>
                        <Text color={buttonColors[type]} weight="bold">
                            {buttonTextes[type]}
                        </Text>
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

MilesActionModal.propTypes = {
    description: node,
    id: string,
    onClose: func,
    title: node,
    type: oneOf(['success', 'failure']),
};

export default MilesActionModal;
