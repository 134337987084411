import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'src/shared/ui/icons/Avatar';
import { getAvatarPreviewLink } from 'store/modules/profile';

import { Divider } from '@s7/ui-kit';

import { useAvatarIconStyles } from './styles';

export const AvatarIcon = (): JSX.Element => {
    const avatarUrl = useSelector(getAvatarPreviewLink);
    const [classes] = useAvatarIconStyles();

    return (
        <>
            {/* TODO: заменить на Divider из @s7/ui, должен появится в версии 0.1.0-alpha.9 */}
            <Divider classes={{ root: classes.root }} orientation="vertical" />
            {!avatarUrl ? (
                <Avatar />
            ) : (
                <div
                    className={classes.icon}
                    style={{ backgroundImage: `url(${avatarUrl})` }}
                />
            )}
        </>
    );
};
