import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func, oneOf, string } from 'prop-types';
import { Dictionary } from 'shared';
import { copyText } from 'utils';
import { normalizeNumber } from 'utils/format';

import { Link, Text } from '@s7/ui-kit';
import { Copy } from '@s7/ui-kit/icons';

import Pattern from './pattern.svg';
import cx from './PriorityCard.sss';
import { S7PriorityTitle } from './S7PriorityTitle';
import S7PriorityTitlePrint from './S7PriorityTitlePrint.svg';

import { addNotification } from '../../store/modules/shared';

const TEXTS = {
    copyNumber: <Dictionary text="com.title.copy-number" />,
};

export const PriorityCard = React.memo(function PriorityCard(props) {
    const dispatch = useDispatch();
    const { level, memberId, firstName, lastName } = props;
    const textColor = level === 'Platinum' ? 'W100' : 'N900';

    const showQRcodeNotification = useCallback(() => {
        dispatch(
            addNotification({
                delay: 3000,
                message: (
                    <Dictionary text="com.title.copy-number-success" html />
                ),
                type: 'success',
            }),
        );
    }, [dispatch]);

    const handleCopy = React.useCallback(() => {
        copyText(normalizeNumber(memberId), {
            onSuccess: showQRcodeNotification,
        });
    }, [memberId, showQRcodeNotification]);

    return (
        <div className={cx('root', { [`root_${level}`]: level })}>
            <div className={cx('container')}>
                <div className={cx('title')}>
                    <S7PriorityTitle level={level} />
                </div>
                <div className={cx('title', 'print')}>
                    <S7PriorityTitlePrint />
                </div>

                <div className={cx('number')}>
                    <Text
                        classes={{ root: cx('number_text') }}
                        color={textColor}
                        weight="semibold"
                    >
                        {memberId}
                    </Text>
                </div>

                <div className={cx('copy-number')}>
                    <Link
                        as="button"
                        color="G600"
                        icon={<Copy />}
                        type="button"
                        onClick={handleCopy}
                    >
                        {TEXTS.copyNumber}
                    </Link>
                </div>
                <div className={cx('level', 'print')}>
                    <Text size="s">{level}</Text>
                </div>

                <div className={cx('card-holder')}>
                    <Text color={textColor} noWrap>
                        {firstName}&nbsp;
                    </Text>
                    <Text color={textColor} noWrap>
                        {lastName}
                    </Text>
                </div>
            </div>

            <Pattern className={cx('pattern')} />
        </div>
    );
});

PriorityCard.propTypes = {
    firstName: string.isRequired,
    lastName: string.isRequired,
    level: oneOf([
        'Classic',
        'ClassicJunior',
        'ClassicMaster',
        'ClassicExpert',
        'ClassicTop',
        'Silver',
        'Gold',
        'Platinum',
    ]),
    memberId: string.isRequired,
    onCopy: func,
};
