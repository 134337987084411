import React, { PureComponent } from 'react';
import { COOKIES } from 'consts';
import { bool, func, string } from 'prop-types';
import { cookieService } from 'services';
import { v4 as uuidv4 } from 'uuid';

import { GoogleWalletLightXs } from '@s7/ui-kit/icons';

import cx from './AddGooglePay.sss';

const lang = () => cookieService.get(COOKIES.LANG) || 'ru';

export default class AddGooglePay extends PureComponent {
    static propTypes = {
        block: bool,
        fullWidth: bool,
        isOpen: bool,
        jwt: string,
        onSuccess: func,
    };

    constructor(props) {
        super(props);
        this.state = {
            hiddenWrapperKey: uuidv4(),
            init: false,
            rendered: false,
            wrapperKey: uuidv4(),
        };
    }

    componentDidMount() {
        this.check();

        this.checkInterval = setInterval(() => this.check(), 500);
    }

    componentWillUnmount() {
        clearInterval(this.checkInterval);
    }

    check() {
        if (typeof gapi !== 'undefined' && !this.state.init && this.props.jwt) {
            this.renderButton();
        }

        if (this.state.init) {
            clearInterval(this.checkInterval);
        }
    }

    renderButton = () => {
        const { hiddenWrapperKey, wrapperKey } = this.state;

        // eslint-disable-next-line no-undef
        if (gapi.savetoandroidpay) {
            this.setState({ init: true });
            const { jwt, onSuccess, block, fullWidth } = this.props;

            window.addGooglePaySuccessHandler = () => {
                onSuccess();
            };

            // eslint-disable-next-line no-undef, no-underscore-dangle
            window.___gcfg = { lang: lang() };

            // eslint-disable-next-line no-undef
            gapi.savetoandroidpay.render(hiddenWrapperKey, {
                height: 'standard',
                jwt,
                lang: lang(),
                onsuccess: 'addGooglePaySuccessHandler',
                ...(fullWidth && { size: 'matchparent' }),
                theme: 'dark',
            });

            setTimeout(() => {
                // eslint-disable-next-line no-undef
                gapi.savetoandroidpay.render(wrapperKey, {
                    height: 'standard',
                    jwt,
                    lang: lang(),
                    onsuccess: 'addGooglePaySuccessHandler',
                    ...(fullWidth && { size: 'matchparent' }),
                    theme: 'dark',
                    ...(block ? { size: 'matchparent' } : {}),
                });
                this.setState({ rendered: true });
            }, 1000);
        }
    };

    render() {
        const { hiddenWrapperKey, wrapperKey, rendered } = this.state;
        const { jwt } = this.props;

        if (!jwt) {
            return null;
        }

        return (
            <div className={cx('root')}>
                <div
                    key={hiddenWrapperKey}
                    className={cx('AddWalletWrapperHidden')}
                    id={hiddenWrapperKey}
                />
                <div
                    key={wrapperKey}
                    className={cx('AddWalletWrapper')}
                    id={wrapperKey}
                />
                {rendered && (
                    <div className={cx('button')}>
                        <span className={cx('text')}>
                            {lang() === 'ru' ? 'Добавить в' : 'Save to'}{' '}
                        </span>
                        <GoogleWalletLightXs
                            classes={{ root: cx('icon') }}
                            height={16}
                            width={40}
                        />
                    </div>
                )}
            </div>
        );
    }
}
