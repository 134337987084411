import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Dictionary from 'shared/Dictionary';
import { resetScroll } from 'src/shared/lib/windowUtils';
import { getLoyalLevel, getMiles } from 'store/modules/loyalty';
import {
    fetch as fetchProfile,
    isLoading as getLoading,
} from 'store/modules/profile';
import {
    getActiveSubscription,
    getHasActiveSubscriptions,
} from 'store/modules/promo';
import { RULE_SUBSCRIPTIONS } from 'utils/allowFeature';
import { noun, numberWithSpaces } from 'utils/format';

import { PriorityStatus as PriorityStatusUI } from '@s7/ui-kit';

type PriorityStatusProps = Parameters<typeof PriorityStatusUI>[0];

const link = undefined;

export const PriorityStatus = (): JSX.Element => {
    const router = useRouter();
    const miles = useSelector(getMiles);
    const isLoading = useSelector(getLoading);
    const dispatch = useDispatch();
    const loyaltyLevel = useSelector(getLoyalLevel);

    const activeSubscription = useSelector(getActiveSubscription);
    const hasActiveSubscription = useSelector(getHasActiveSubscriptions);

    const onFetchProfile = useCallback(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const priorityStatusProps = useMemo<Partial<PriorityStatusProps>>(() => {
        if (hasActiveSubscription && RULE_SUBSCRIPTIONS) {
            return {
                headerGradient:
                    activeSubscription.code === 'light'
                        ? 'seagreen'
                        : 'aquamarine',
                headerLink: '/subscriptions',
                headerProps: {
                    'data-test': 'priorityStatusTest',
                    onClick: e => {
                        e.preventDefault();
                        document.body.click();
                        router.push('/subscriptions').then(resetScroll);
                    },
                },
                headerSubtitle: (
                    <Dictionary
                        text={`com.header.subscriptions.${
                            activeSubscription.prolongation
                                ? 'prolongation'
                                : 'active-until'
                        }`}
                        transform={t =>
                            t.replace('%date%', activeSubscription.endDate)
                        }
                    />
                ),
                headerTitle: activeSubscription.title,
                link,
            };
        }

        return {
            link,
        };
    }, [
        router,
        hasActiveSubscription,
        activeSubscription.title,
        activeSubscription.prolongation,
        activeSubscription.code,
        activeSubscription.endDate,
    ]);

    return (
        <PriorityStatusUI
            desc={
                <>
                    {numberWithSpaces(miles)}&nbsp;
                    {noun(miles, [
                        <Dictionary
                            key={'com.units.miles.one'}
                            text="com.units.miles.one"
                        />,
                        <Dictionary
                            key={'com.units.miles.once'}
                            text="com.units.miles.once"
                        />,
                        <Dictionary
                            key={'com.units.miles.mult'}
                            text="com.units.miles.mult"
                        />,
                    ])}
                </>
            }
            isLoading={isLoading}
            refreshAction={onFetchProfile}
            theme="b2c_primary"
            title={loyaltyLevel}
            {...priorityStatusProps}
        />
    );
};
