import React, { forwardRef, useEffect, useRef } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { number, object } from 'prop-types';
import IconButton from 'ui-kit/atoms/IconButton';

import cx from './Avatar.sss';

const calculateCropperData = ({
    containerData: { height, width },
    cropBoxData: {
        height: cropBoxHeight,
        width: cropBoxWidth,
        left: cropBoxLeft,
        top: cropBoxTop,
    },
    imageData: { naturalHeight, naturalWidth, rotate },
}) => {
    const isVertical = (rotate / 90) % 2 === 1;

    const h = isVertical ? naturalHeight : naturalWidth;
    const w = isVertical ? naturalWidth : naturalHeight;

    const heightProportion = height / h;
    const widthProportion = width / w;

    if (heightProportion < widthProportion) {
        const canvasWidth = w * heightProportion;
        const left = (width - canvasWidth) / 2;

        return {
            canvasData: {
                height,
                left,
            },
            cropBoxData: {
                height: cropBoxHeight > height ? height : cropBoxWidth,
                left: left > cropBoxLeft ? left : cropBoxLeft,
            },
        };
    }

    const canvasHeight = h * widthProportion;
    const top = (height - canvasHeight) / 2;

    return {
        canvasData: {
            top,
            width,
        },
        cropBoxData: {
            top: top > cropBoxTop ? top : cropBoxTop,
            width: cropBoxWidth > width ? width : cropBoxWidth,
        },
    };
};

const setCropperData = (cropper, { canvasData, cropBoxData }) => {
    cropper
        .setCropBoxData({ ...canvasData, height: 1, width: 1 })
        .setCanvasData(canvasData)
        .setCropBoxData(cropBoxData);
};

const AvatarEditor = React.memo(
    forwardRef(function AvatarEditor(
        { image, minCroppedWidth = 200, minCroppedHeight = 200 },
        cropperRef,
    ) {
        const imageRef = useRef(null);

        const rotateLeft = () => {
            const cropper = cropperRef.current;

            // we can't use rotate(-90) since backend doesn't apply negative value
            const rotate = (cropper.imageData.rotate || 360) - 90;
            const cropperData = calculateCropperData(cropper);

            cropper.rotateTo(rotate);
            setCropperData(cropper, cropperData);
        };

        const rotateRight = () => {
            const cropper = cropperRef.current;
            const cropperData = calculateCropperData(cropper);

            cropper.rotate(90);
            setCropperData(cropper, cropperData);
        };

        useEffect(() => {
            imageRef.current.src = window.URL.createObjectURL(image);
            cropperRef.current = new Cropper(imageRef.current, {
                aspectRatio: 1,
                crop(event) {
                    const { width, height } = event.detail;

                    if (width < minCroppedWidth || height < minCroppedHeight) {
                        cropperRef.current.setData({
                            height: Math.max(minCroppedHeight, height),
                            width: Math.max(minCroppedWidth, width),
                        });
                    }
                },
                dragMode: 'none',
                guides: false,
                viewMode: 2,
                zoomable: false,
            });

            return () => {
                cropperRef.current.destroy();
                cropperRef.current = null;
            };
        }, [cropperRef, image, minCroppedHeight, minCroppedWidth]);

        return (
            <>
                <img ref={imageRef} alt="Avatar" id="image" />
                <div className={cx('editor_rotate-buttons')}>
                    <div className={cx('editor_rotate-left')}>
                        <IconButton
                            icon="reload"
                            theme="white"
                            onClick={rotateLeft}
                        />
                    </div>
                    <IconButton
                        icon="reload"
                        theme="white"
                        onClick={rotateRight}
                    />
                </div>
            </>
        );
    }),
);

AvatarEditor.propTypes = {
    image: object,
    minCroppedHeight: number,
    minCroppedWidth: number,
};

export default AvatarEditor;
