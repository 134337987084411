import React from 'react';
import { Badge } from 'src/shared/ui/Badge';

import { useActiveLabelBadgeStyles } from './styles';

export const ActiveLabelBadge = (): JSX.Element => {
    const [classes] = useActiveLabelBadgeStyles();

    return (
        <Badge
            className={classes.active}
            text="com.label.active"
            textColor="white"
            textSize="sm1"
        />
    );
};
