import React, { useCallback, useMemo, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useAnalyticsContext } from 'contexts';
import { useWindowSize } from 'hooks';
import { bool, func, node, string } from 'prop-types';
import { Dictionary } from 'shared';
import { detection } from 'utils';

import { Button, Link, StatusMessage } from '@s7/ui-kit';
import { Print } from '@s7/ui-kit/icons';

import { PriorityCard } from './PriorityCard';
import cx from './PriorityCardWithQRAndBarCode.sss';

import { modalIds } from '../../data';
import AddAppleWallet from '../../ui-kit/atoms/AddAppleWallet';
import AddGooglePay from '../../ui-kit/atoms/AddGooglePay';
import { Barcode } from '../Barcode';
import { ProfileModal } from '../Modal';
import { QRCode } from '../QRCode';

const QR_CODE_SIZE = 176.55;

const TEXTS = {
    print: <Dictionary text="com.status-priority.print" />,
    title: <Dictionary text="com.status-priority.title" />,
};

export const PriorityCardWithQRAndBarCode = React.memo(
    function PriorityCardWithQRAndBarCode(props) {
        const {
            barCodeInput,
            firstName,
            isNotificationOpen,
            isOpen,
            jwt,
            lastName,
            level,
            memberId,
            onClose,
            onCloseNotification,
            qrCodeInput,
        } = props;
        const { isDesktop } = useWindowSize();
        const { sendEvent } = useAnalyticsContext();

        const rootRef = useRef();

        const handleAddAppleWalletClick = useCallback(() => {
            sendEvent({
                event: 'click_cta',
                flow: 's7priority_card',
                result: 'add_to_apple',
                step: 'details',
            });
        }, [sendEvent]);

        const handleAddGooglePayClick = useCallback(() => {
            sendEvent({
                event: 'click_cta',
                flow: 's7priority_card',
                result: 'add_to_google',
                step: 'details',
            });
        }, [sendEvent]);

        const contentCb = useCallback(() => rootRef.current, []);
        const triggerCb = useCallback(
            () => (
                <div className={cx('button')}>
                    <Button size="large" theme="secondary" block>
                        <Print size="md" /> {TEXTS.print}
                    </Button>
                </div>
            ),
            [],
        );

        const addToPayButton = useMemo(() => {
            if (detection.isAppleWallet) {
                return <AddAppleWallet onClick={handleAddAppleWalletClick} />;
            }

            return (
                Boolean(jwt) && (
                    <AddGooglePay
                        isOpen={isOpen}
                        jwt={jwt}
                        block
                        fullWidth
                        onSuccess={handleAddGooglePayClick}
                    />
                )
            );
        }, [handleAddAppleWalletClick, handleAddGooglePayClick, jwt, isOpen]);

        const handleBeforePrint = useCallback(() => {
            sendEvent({
                event: 'click_cta',
                flow: 's7priority_card',
                result: 'print',
                step: 'details',
            });
        }, [sendEvent]);

        const actions = useMemo(
            () => (
                <>
                    {addToPayButton && (
                        <div className={cx('button')}>{addToPayButton}</div>
                    )}
                    {isDesktop && (
                        <ReactToPrint
                            content={contentCb}
                            trigger={triggerCb}
                            onBeforePrint={handleBeforePrint}
                        />
                    )}
                </>
            ),
            [
                addToPayButton,
                contentCb,
                handleBeforePrint,
                isDesktop,
                triggerCb,
            ],
        );

        return (
            <ProfileModal
                actions={isDesktop ? actions : null}
                actionsLayout="vertical"
                id={modalIds.QR_MODAL}
                isOpen={isOpen}
                title={TEXTS.title}
                onClose={onClose}
            >
                <div ref={rootRef} className={cx('root')}>
                    {isNotificationOpen && (
                        <div className={cx('notification')}>
                            <StatusMessage onClose={onCloseNotification}>
                                <Dictionary
                                    transform={text =>
                                        text.replace('%status%', level)
                                    }
                                    text="com.status-priority.notification.part1"
                                    html
                                />
                                <Link
                                    as="a"
                                    href="https://www.s7.ru/ru/new-classic-statuses/"
                                    target="_blank"
                                    view="info"
                                >
                                    <Dictionary text="com.status-priority.notification.know_more" />
                                </Link>
                                <Dictionary text="com.status-priority.notification.part2" />
                            </StatusMessage>
                        </div>
                    )}

                    <div className={cx('container')}>
                        <div className={cx('barcode')}>
                            <Barcode
                                displayValue={false}
                                height={60}
                                margin={0}
                                value={barCodeInput}
                            />
                        </div>
                        <div className={cx('priority-card')}>
                            <PriorityCard
                                {...{ firstName, lastName, level, memberId }}
                            />
                        </div>
                        <div className={cx('qr-code')}>
                            <QRCode size={QR_CODE_SIZE} value={qrCodeInput} />
                        </div>
                    </div>
                    {isDesktop || <div className={cx('action')}>{actions}</div>}
                </div>
            </ProfileModal>
        );
    },
);

PriorityCardWithQRAndBarCode.propTypes = {
    addButton: node,
    barCodeInput: string,
    firstName: string,
    isNotificationOpen: bool,
    isOpen: bool,
    jwt: string,
    lastName: string,
    level: string,
    memberId: string,
    onClose: func,
    onCloseNotification: func,
    qrCodeInput: string,
};
