import React, { PureComponent } from 'react';
import { node, number, oneOf } from 'prop-types';

import cx from './GlowPoint.sss';

export default class GlowPoint extends PureComponent {
    static propTypes = {
        children: node,
        right: number,
        theme: oneOf(['', 'red']),
        top: number,
    };

    static defaultProps = {
        right: 3,
        top: 3,
    };

    render() {
        const { children, top, right, theme } = this.props;

        return (
            <span
                className={cx('GlowPoint', {
                    [`GlowPoint_theme_${theme}`]: theme,
                })}
            >
                {children}
                <span className={cx('point')} style={{ right, top }} />
            </span>
        );
    }
}
