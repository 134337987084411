import { useSelector } from 'react-redux';
import {
    getEmailConfirmed,
    getTelephoneConfirmed,
} from 'store/modules/profile';

export const useContactNotificationText = (): string => {
    const emailConfirmed = useSelector(getEmailConfirmed);
    const phoneConfirmed = useSelector(getTelephoneConfirmed);

    if (!emailConfirmed && !phoneConfirmed) {
        return 'com.global.confirm-contacts';
    }

    if (!emailConfirmed) {
        return 'com.global.confirm.email';
    }

    if (!phoneConfirmed) {
        return 'com.global.confirm.phone';
    }

    return 'com.global.add-contacts';
};
