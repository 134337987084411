import moment from 'moment';
import { pathOr } from 'ramda';
import { createSelector } from 'reselect';

import { RULE_SOCIAL_INSTAGRAM } from '../../../utils/allowFeature';

export const getIsPublicProfileError = state => state.publicProfile.isError;
export const getIsPublicProfileLoading = state => state.publicProfile.isLoading;
export const getIsPublicProfileInitialized = state =>
    state.publicProfile.isInitialized;
export const getProfileId = state => state.publicProfile.profileId;
export const getStatistics = state =>
    pathOr(null, ['publicProfile', 'statistics'], state);

export const getIsPublicMediaError = state => state.publicProfile.isMediaError;
export const getIsPublicMediaLoading = state =>
    state.publicProfile.isMediaLoading;
export const getIsPublicMediaInitialized = state =>
    state.publicProfile.isMediaInitialized;

export const getIsPublicNearestFlightError = state =>
    state.publicProfile.isNearestFlightError;
export const getIsPublicNearestFlightLoading = state =>
    state.publicProfile.isNearestFlightLoading;
export const getIsPublicNearestFlightInitialized = state =>
    state.publicProfile.isNearestFlightInitialized;

export const getAvatar = pathOr('', [
    'publicProfile',
    'data',
    'avatar',
    'previewLink',
]);
export const getName = pathOr('', ['publicProfile', 'data', 'name']);

export const getStatisticsDistance = pathOr(0, [
    'publicProfile',
    'statistics',
    'flightStatistics',
    'distance',
    'amount',
]);

export const getStatisticsDuration = pathOr(0, [
    'publicProfile',
    'statistics',
    'flightStatistics',
    'duration',
    'amount',
]);

export const getStatisticsDurationHours = createSelector(
    getStatisticsDuration,
    minutes => Math.round(moment.duration(minutes, 'minutes').asHours()),
);

export const getStatisticsCountriesAmount = pathOr(0, [
    'publicProfile',
    'statistics',
    'locationStatistics',
    'countryStatistics',
    'uniqueAmount',
]);

export const getStatisticsCities = pathOr(
    [],
    [
        'publicProfile',
        'statistics',
        'locationStatistics',
        'cityStatistics',
        'locations',
    ],
);

export const getStatisticsCitiesAmount = pathOr(0, [
    'publicProfile',
    'statistics',
    'locationStatistics',
    'cityStatistics',
    'uniqueAmount',
]);

export const getStatisticsAirportsAmount = pathOr(0, [
    'publicProfile',
    'statistics',
    'locationStatistics',
    'airportStatistics',
    'uniqueAmount',
]);

export const getAchievements = pathOr(null, ['publicProfile', 'achievements']);
export const getMedias = state =>
    RULE_SOCIAL_INSTAGRAM
        ? pathOr(null, ['publicProfile', 'medias'], state)
        : null;

export const getNearestFlight = createSelector(
    pathOr({}, ['publicProfile', 'nearestFlight']),
    getAvatar,
    (nearestFlight, avatar) => ({
        ...nearestFlight,
        ...(avatar ? { avatar } : {}),
    }),
);
