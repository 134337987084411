import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { numberWithSpaces } from 'utils/format';

import { PriorityCardWithQRAndBarCode } from '../../components/PriorityCard';
import {
    getJwt,
    getLoyalLevel,
    getMembershipID,
    getStatusExpirationDate,
} from '../../store/modules/loyalty';
import { getFullEngName, getIsFullProfile } from '../../store/modules/profile';
import {
    changePriorityCardOpen,
    getIsPriorityCardOpen,
} from '../../store/modules/shared';

const generateQRCodeInput = ({ memberId, fullName, level, expiration }) =>
    `S7-${memberId}-${fullName}-${level}-${expiration}`;

const DATE_FORMAT = 'YYMM';
const DATE_INDEFINITELY_FORMAT = '99MM';
const CLASSIC_LEVEL = 'classic';

const codeByLevel = {
    classic: 0,
    'classic expert': 5,
    'classic master': 6,
    'classic top': 4,
    gold: 2,
    platinum: 1,
    silver: 3,
};

const generateBarcodeInput = ({ memberId, level, expiration }) =>
    `421${memberId}${codeByLevel[level]}${expiration}`;

export const S7PriorityCard = () => {
    const dispatch = useDispatch();

    const isFullProfile = useSelector(getIsFullProfile);
    const loyalLevel = useSelector(getLoyalLevel);
    const memberId = useSelector(getMembershipID) || '';
    const expirationDate = useSelector(getStatusExpirationDate) || moment();
    const fullName = useSelector(getFullEngName);
    const jwt = useSelector(getJwt);
    const isPriorityCardOpen = useSelector(getIsPriorityCardOpen);
    const [firstName, lastName] = useMemo(
        () => (fullName || '').split(' '),
        [fullName],
    );

    const [isNotificationOpen, setNotificationOpen] = useState(true);

    const level = loyalLevel.toLowerCase();
    const expiration = expirationDate.format(
        level === CLASSIC_LEVEL ? DATE_INDEFINITELY_FORMAT : DATE_FORMAT,
    );

    const handleClose = useCallback(() => {
        dispatch(changePriorityCardOpen(false));

        setNotificationOpen(true);
    }, [dispatch]);

    const handleCloseNotification = useCallback(() => {
        setNotificationOpen(false);
    }, []);

    const qrCodeInput = generateQRCodeInput({
        expiration,
        fullName,
        level,
        memberId,
    });

    const barCodeInput = generateBarcodeInput({
        expiration,
        level,
        memberId,
    });

    return (
        isFullProfile && (
            <PriorityCardWithQRAndBarCode
                {...{
                    barCodeInput,
                    firstName,
                    isNotificationOpen,
                    isOpen: isPriorityCardOpen,
                    jwt,
                    lastName,
                    level: loyalLevel,
                    memberId: numberWithSpaces(memberId),
                    onClose: handleClose,
                    onCloseNotification: handleCloseNotification,
                    qrCodeInput,
                }}
            />
        )
    );
};
