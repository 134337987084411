import React from 'react';
import { string } from 'prop-types';

export const S7PriorityTitle = ({ level }) => {
    const fill = level === 'Gold' ? '#A69055' : '#7A869A';
    const isPlatinum = level === 'Platinum';

    const gradientBody = React.useMemo(
        () => (
            <>
                <stop stopColor="#BEEECC" />
                <stop offset="0.167835" stopColor="#F0C9BE" />
                <stop offset="0.335359" stopColor="#BEC9F1" />
                <stop offset="0.506411" stopColor="#BEEECC" />
                <stop offset="1" stopColor="#F0C9BE" />
            </>
        ),
        [],
    );

    return isPlatinum ? (
        <svg
            fill="none"
            height="32"
            viewBox="0 0 100 32"
            width="100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M41.6753 14.0354H44.6856C46.2097 14.0354 47.0114 13.2025 47.0114 11.9134C47.0114 10.5866 46.1695 9.83216 44.7241 9.83216H41.6753V14.0354ZM44.9656 7.33334C46.6307 7.33334 47.8736 7.80993 48.7155 8.6619C49.5186 9.47509 50 10.5866 50 11.8951C50 14.5715 48.2155 16.3752 45.2055 16.3752H41.6753V21.3333H38.6667V7.33334H44.9656ZM60.6667 10H58V7.33334H60.6667V10ZM53.4201 13.0288H53.3585V11.4492H50.6667V21.3333H53.4613V16.5167C53.4613 14.4353 54.7771 13.5306 56.6065 13.7039H56.6667V11.4106C56.5019 11.3535 56.3164 11.3333 56.0105 11.3333C54.7771 11.3333 54.0573 11.9117 53.4201 13.0288ZM58 21.3333H60.6667V11.3333H58V21.3333ZM66.6667 13.3605C68.3191 13.3605 69.2544 14.6984 69.2544 16.6664C69.2544 18.635 68.3191 19.9523 66.6667 19.9523C64.9944 19.9523 64.0792 18.635 64.0792 16.6664C64.0792 14.6984 64.9944 13.3605 66.6667 13.3605ZM66.6667 11.3333C63.5019 11.3333 61.3334 13.6559 61.3334 16.6664C61.3334 19.6782 63.5019 22 66.6667 22C69.8317 22 72 19.6782 72 16.6664C72 13.6559 69.8317 11.3333 66.6667 11.3333ZM76.025 13.0288H76.0869C76.7226 11.9117 77.4424 11.3333 78.674 11.3333C78.9831 11.3333 79.1686 11.3535 79.3334 11.4106V13.7039H79.2699C77.4424 13.5306 76.1281 14.4353 76.1281 16.5167V21.3333H73.3334V11.4492H76.025V13.0288ZM82.6667 10H80V7.33334H82.6667V10ZM80 21.3333H82.6667V11.3333H80V21.3333ZM84.7783 11.1654H83.3334V12.9459H84.7783V18.8997C84.7783 20.8175 86.2626 21.3333 87.6057 21.3333C88.7024 21.3333 89.3334 21.293 89.3334 21.293V19.3148C89.3334 19.3148 88.8658 19.3348 88.5205 19.3348C87.9101 19.3348 87.4834 19.0776 87.4834 18.3266V12.9459H89.2721V11.1654H87.4834V8.00001H84.7783V11.1654ZM91.0753 22.5712H92.049C93.1445 22.5712 93.5907 22.0238 93.5907 21.2012C93.5907 20.6528 93.3888 20.0061 92.8002 18.4788L90 11.3333H92.9005L94.4422 15.8564C94.7661 16.7772 95.1119 18.0491 95.1119 18.0491H95.1526C95.1526 18.0491 95.4359 16.7772 95.7598 15.8564L97.2218 11.3333H100L96.3687 21.6316C95.5783 23.8637 94.8068 24.6667 92.6969 24.6667H91.0753V22.5712Z"
                fill="url(#paint0_linear)"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M32.6667 16.0034C32.6667 24.8427 25.5083 32 16.6715 32C7.83368 32 0.666687 24.8427 0.666687 16.0034C0.666687 7.16414 7.83368 0 16.6715 0C25.5084 0 32.6667 7.16414 32.6667 16.0034ZM15.4256 7.71514C12.919 7.71514 11.2928 7.8537 10.1157 8.29069C8.13751 9.06128 6.77139 10.6763 6.77139 12.7988C6.77139 14.5665 7.59452 15.8467 9.97667 17.3192C12.5184 18.86 13.0254 19.3692 13.0254 20.2977C13.0254 21.3138 12.0298 22.4704 9.46815 22.4704C8.57406 22.4704 7.22736 22.3467 6.36684 22.1361L5.95623 22.0326C5.72718 22.6467 5.20721 24.0993 5.04867 24.6263L5.45523 24.7459C6.2293 24.9928 8.06632 25.1856 9.38248 25.1856C13.7609 25.1856 16.3732 22.5403 16.3732 19.7704C16.3732 18.0683 15.6006 16.7747 12.9888 15.1451C11.6787 14.3393 11.0111 13.8167 10.6057 13.4122C10.277 12.9899 10.2406 12.6756 10.2406 12.3436C10.2406 12.0266 10.3111 11.6614 10.5201 11.3938C10.7461 11.063 11.1607 10.8523 11.7217 10.6783C12.2984 10.487 13.3669 10.482 15.031 10.482H16.5805L17.6869 7.71526H15.4256V7.71514ZM19.3492 7.71514H27.1355C27.5016 7.71514 27.5716 7.97384 27.4306 8.25544C26.9215 9.51736 22.1177 21.5535 22.1177 21.5535H18.9065C18.9065 21.5535 22.7008 11.8846 23.3303 10.4444C23.1847 10.4444 23.0505 10.4485 22.8717 10.4541C22.5149 10.4651 21.9804 10.4817 20.8235 10.4817H18.2427L19.3492 7.71514Z"
                fill="url(#paint1_linear)"
                fillRule="evenodd"
            />
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear"
                    x1="38.6667"
                    x2="100"
                    y1="24.6667"
                    y2="24.6667"
                >
                    {gradientBody}
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear"
                    x1="0.666687"
                    x2="32.6667"
                    y1="32"
                    y2="32"
                >
                    {gradientBody}
                </linearGradient>
            </defs>
        </svg>
    ) : (
        <svg
            fill="none"
            height="32"
            viewBox="0 0 100 32"
            width="100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M41.6753 14.0354H44.6856C46.2097 14.0354 47.0114 13.2025 47.0114 11.9134C47.0114 10.5866 46.1695 9.83216 44.7241 9.83216H41.6753V14.0354ZM44.9656 7.33334C46.6307 7.33334 47.8736 7.80993 48.7155 8.6619C49.5186 9.47509 50 10.5866 50 11.8951C50 14.5715 48.2155 16.3752 45.2055 16.3752H41.6753V21.3333H38.6667V7.33334H44.9656ZM60.6667 10H58V7.33334H60.6667V10ZM53.4201 13.0288H53.3585V11.4492H50.6667V21.3333H53.4613V16.5167C53.4613 14.4353 54.7771 13.5306 56.6065 13.7039H56.6667V11.4106C56.5019 11.3535 56.3164 11.3333 56.0105 11.3333C54.7771 11.3333 54.0573 11.9117 53.4201 13.0288ZM58 21.3333H60.6667V11.3333H58V21.3333ZM66.6667 13.3605C68.3191 13.3605 69.2544 14.6984 69.2544 16.6664C69.2544 18.635 68.3191 19.9523 66.6667 19.9523C64.9944 19.9523 64.0792 18.635 64.0792 16.6664C64.0792 14.6984 64.9944 13.3605 66.6667 13.3605ZM66.6667 11.3333C63.5019 11.3333 61.3334 13.6559 61.3334 16.6664C61.3334 19.6782 63.5019 22 66.6667 22C69.8317 22 72 19.6782 72 16.6664C72 13.6559 69.8317 11.3333 66.6667 11.3333ZM76.025 13.0288H76.0869C76.7226 11.9117 77.4424 11.3333 78.674 11.3333C78.9831 11.3333 79.1686 11.3535 79.3334 11.4106V13.7039H79.2699C77.4424 13.5306 76.1281 14.4353 76.1281 16.5167V21.3333H73.3334V11.4492H76.025V13.0288ZM82.6667 10H80V7.33334H82.6667V10ZM80 21.3333H82.6667V11.3333H80V21.3333ZM84.7783 11.1654H83.3334V12.9459H84.7783V18.8997C84.7783 20.8175 86.2626 21.3333 87.6057 21.3333C88.7024 21.3333 89.3334 21.293 89.3334 21.293V19.3148C89.3334 19.3148 88.8658 19.3348 88.5205 19.3348C87.9101 19.3348 87.4834 19.0776 87.4834 18.3266V12.9459H89.2721V11.1654H87.4834V8.00001H84.7783V11.1654ZM91.0753 22.5712H92.049C93.1445 22.5712 93.5907 22.0238 93.5907 21.2012C93.5907 20.6528 93.3888 20.0061 92.8002 18.4788L90 11.3333H92.9005L94.4422 15.8564C94.7661 16.7772 95.1119 18.0491 95.1119 18.0491H95.1526C95.1526 18.0491 95.4359 16.7772 95.7598 15.8564L97.2218 11.3333H100L96.3687 21.6316C95.5783 23.8637 94.8068 24.6667 92.6969 24.6667H91.0753V22.5712Z"
                fill={fill}
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M32.6667 16.0034C32.6667 24.8427 25.5083 32 16.6715 32C7.83368 32 0.666687 24.8427 0.666687 16.0034C0.666687 7.16414 7.83368 0 16.6715 0C25.5084 0 32.6667 7.16414 32.6667 16.0034ZM15.4256 7.71514C12.919 7.71514 11.2928 7.8537 10.1157 8.29069C8.13751 9.06128 6.77139 10.6763 6.77139 12.7988C6.77139 14.5665 7.59452 15.8467 9.97667 17.3192C12.5184 18.86 13.0254 19.3692 13.0254 20.2977C13.0254 21.3138 12.0298 22.4704 9.46815 22.4704C8.57406 22.4704 7.22736 22.3467 6.36684 22.1361L5.95623 22.0326C5.72718 22.6467 5.20721 24.0993 5.04867 24.6263L5.45523 24.7459C6.2293 24.9928 8.06632 25.1856 9.38248 25.1856C13.7609 25.1856 16.3732 22.5403 16.3732 19.7704C16.3732 18.0683 15.6006 16.7747 12.9888 15.1451C11.6787 14.3393 11.0111 13.8167 10.6057 13.4122C10.277 12.9899 10.2406 12.6756 10.2406 12.3436C10.2406 12.0266 10.3111 11.6614 10.5201 11.3938C10.7461 11.063 11.1607 10.8523 11.7217 10.6783C12.2984 10.487 13.3669 10.482 15.031 10.482H16.5805L17.6869 7.71526H15.4256V7.71514ZM19.3492 7.71514H27.1355C27.5016 7.71514 27.5716 7.97384 27.4306 8.25544C26.9215 9.51736 22.1177 21.5535 22.1177 21.5535H18.9065C18.9065 21.5535 22.7008 11.8846 23.3303 10.4444C23.1847 10.4444 23.0505 10.4485 22.8717 10.4541C22.5149 10.4651 21.9804 10.4817 20.8235 10.4817H18.2427L19.3492 7.71514Z"
                fill={fill}
                fillRule="evenodd"
            />
        </svg>
    );
};

S7PriorityTitle.propTypes = {
    level: string,
};
