import React, { useCallback, useEffect, useRef, useState } from 'react';
import { arrayOf, bool, func, node, number, shape, string } from 'prop-types';
import { Dictionary, Wrapper } from 'shared';

import cx from './ProfileHeader.sss';

import { useStyleVariantContext } from '../../contexts';
import { MainMenu } from '../MainMenu';
import UserInfo from '../UserInfo';
import UserMediaInfo from '../UserMediaInfo';

const MILES_UNITS = [
    <Dictionary text="com.units.miles.one" />,
    <Dictionary text="com.units.miles.once" />,
    <Dictionary text="com.units.miles.mult" />,
];

const ProfileHeader = ({
    avatarError,
    customerValue,
    deleteAvatar,
    businessUpgrade,
    flights,
    getTestId,
    imgURL,
    isAvatarDeleting,
    isAvatarSaving,
    isDoctor,
    isEnName,
    isLoyaltyInitialized,
    isPublic,
    loyalLevel,
    membershipID,
    miles,
    milesBonus,
    milesStatus,
    name,
    onOpenPriorityCard,
    resetAvatarError,
    saveAvatar,
    surname,
    tabs,
}) => {
    const { variant } = useStyleVariantContext();

    const [shadowRight, setShadowRight] = useState(false);
    const [shadowLeft, setShadowLeft] = useState(false);

    const tabsRef = useRef(null);
    const tabsInnerRef = useRef(null);

    const handleScroll = useCallback(() => {
        if (tabsRef.current && tabsInnerRef.current) {
            const outerWidth = tabsRef.current.clientWidth;
            const innerWidth = tabsInnerRef.current.clientWidth;

            let localShadowLeft = false;

            let localShadowRight = false;

            if (outerWidth <= innerWidth) {
                const { scrollLeft } = tabsRef.current;

                localShadowLeft = scrollLeft > 0;
                localShadowRight = innerWidth - outerWidth > scrollLeft;
            }

            if (
                shadowLeft !== localShadowLeft ||
                shadowRight !== localShadowRight
            ) {
                setShadowLeft(localShadowLeft);
                setShadowRight(localShadowRight);
            }
        }
    }, [shadowLeft, shadowRight]);

    useEffect(() => {
        window.addEventListener('resize', handleScroll);
        const tabsReference = tabsRef.current;

        if (tabsReference) {
            handleScroll();
            tabsReference.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('resize', handleScroll, false);

            if (tabsReference) {
                tabsReference.removeEventListener(
                    'scroll',
                    handleScroll,
                    false,
                );
            }
        };
    }, [handleScroll]);

    const Component = variant === 'media' ? UserMediaInfo : UserInfo;

    return (
        <div className={cx('root', variant === 'media' && 'mediaRoot')}>
            <Wrapper>
                <Component
                    loyalLink="https://www.s7.ru/new-classic-statuses"
                    milesUnits={MILES_UNITS}
                    {...{
                        avatarError,
                        businessUpgrade,
                        customerValue,
                        deleteAvatar,
                        flights,
                        getTestId,
                        imgURL,
                        isAvatarDeleting,
                        isAvatarSaving,
                        isDoctor,
                        isEnName,
                        isLoyaltyInitialized,
                        isPublic,
                        loyalLevel,
                        membershipID,
                        miles,
                        milesBonus,
                        milesStatus,
                        name,
                        onOpenPriorityCard,
                        resetAvatarError,
                        saveAvatar,
                        surname,
                    }}
                />

                <MainMenu isPublic={isPublic} links={tabs} />
            </Wrapper>
        </div>
    );
};

ProfileHeader.propTypes = {
    avatarError: string,
    customerValue: string,
    deleteAvatar: func,
    flights: number,
    getTestId: func,
    imgURL: string,
    isAvatarDeleting: bool,
    isAvatarSaving: bool,
    isDoctor: bool,
    isEnName: bool,
    isLoyaltyInitialized: bool,
    isPublic: bool,
    loyalLevel: string,
    membershipID: string,
    miles: number,
    milesBonus: number,
    milesStatus: number,
    name: string,
    onOpenPriorityCard: func,
    resetAvatarError: func,
    saveAvatar: func,
    settingsTab: shape({
        href: string,
        title: node,
    }),
    surname: string,
    tabs: arrayOf(
        shape({
            href: string,
            id: string,
            title: node,
        }),
    ),
};

ProfileHeader.defaultProps = {
    deleteAvatar: () => {},
    resetAvatarError: () => {},
    saveAvatar: () => {},
    tabs: [],
};

export default ProfileHeader;
