import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'hooks';
import { arrayOf, bool, shape, string } from 'prop-types';
import { keyCodes } from 'utils/keyCodes';

import { CardLabel, Text } from '@s7/ui-kit';
import { LockFilled } from '@s7/ui-kit/icons';

import { AchievementModal } from './AchievementModal';
import cx from './Achievements.sss';

function removeHashFromUrl() {
    window.history.replaceState(
        {},
        document.title,
        window.location.href.split('#')[0],
    );
}

export const AchievementItem = React.memo(function AchievementItem({
    action,
    backgroundColors,
    code,
    description,
    image,
    name,
    thumbnail,
    isActive,
    isMyPublic,
    isNew,
    stub,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const itemRef = useRef(null);
    const { isMobile } = useWindowSize();
    const badgeStyles =
        isActive && backgroundColors
            ? { backgroundColor: `#${backgroundColors?.[0]}` }
            : {};
    const inactive = !isActive;

    const openModal = () => {
        // Очищаем хэш, чтобы не происходил scroll к блоку который был указан hash ранее
        removeHashFromUrl();
        itemRef.current.blur();
        setIsModalOpen(true);
    };

    const handleKeyDown = e => {
        if (e.keyCode === keyCodes.Enter || e.keyCode === keyCodes.Space) {
            openModal();
        }
    };

    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (window.location.hash === `#achievement-${code}`) {
            openModal();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return stub ? (
        <div className={cx('item', 'stub')} />
    ) : (
        <div className={cx({ inactive })}>
            <div
                ref={itemRef}
                className={cx('item')}
                role="button"
                tabIndex={0}
                onClick={openModal}
                onKeyDown={handleKeyDown}
            >
                <span className={cx('badge')} style={badgeStyles}>
                    <img alt="" className={cx('badge-img')} src={thumbnail} />
                    {inactive && (
                        <div className={cx('lock')}>
                            <LockFilled size="md" />
                        </div>
                    )}
                    {isNew && (
                        <CardLabel color="O600" position="topLeft">
                            New!
                        </CardLabel>
                    )}
                </span>
                <br />
                <span className={cx('item-name')}>
                    <Text
                        alignment="center"
                        color={isActive ? undefined : 'N100'}
                        size={isMobile ? 's' : undefined}
                    >
                        {name}
                    </Text>
                </span>
            </div>

            <AchievementModal
                {...{
                    action,
                    badgeStyles,
                    closeModal,
                    code,
                    description,
                    image,
                    inactive,
                    isActive,
                    isModalOpen,
                    isMyPublic,
                    isNew,
                    name,
                }}
            />
        </div>
    );
});

AchievementItem.propTypes = {
    action: shape({
        link: shape({
            inner: bool,
            url: string,
        }),
        text: string,
    }),
    backgroundColors: arrayOf(string),
    buttonTitle: string,
    code: string,
    description: string,
    image: string,
    isActive: bool,
    isMyPublic: bool,
    isNew: bool,
    link: string,
    name: string,
    stub: bool,
    thumbnail: string,
};
