import React from 'react';
import { arrayOf, bool, func, node, string } from 'prop-types';
import { ProfileInfo } from 'src/entities/profile';
import { getScrollbarWidth } from 'utils/scroll';

import { Checkbox, Header } from '@s7/ui-kit';

import cx from './Header.sss';

import LoginWithModal from '../LoginWithModal';

const qaCheckboxesStyles = {
    backgroundColor: 'rgba(255,255,255,0.6)',
    bottom: 0,
    left: 0,
    padding: 10,
    position: 'fixed',
    zIndex: 1000,
};

const HeaderComponent = ({
    avatarUrl,
    getTestId,
    headerAbsolute,
    isLogin,
    isModalOpen,
    isVisibleLanguages,
    language,
    languages,
    loginTitle,
    onChangeLanguage,
    openLogin,
    openUserInfo,
    setOpenUserInfo,
    toggleHeaderAbsolute,
    toggleVisibleLanguage,
}) => {
    return (
        <div
            className={cx('wrapper', { headerAbsolute })}
            {...(isModalOpen
                ? { style: { width: `calc(100% - ${getScrollbarWidth()}px)` } }
                : {})}
        >
            <div>
                {(toggleHeaderAbsolute || toggleVisibleLanguage) && (
                    <div style={qaCheckboxesStyles}>
                        {toggleVisibleLanguage && (
                            <Checkbox
                                checked={isVisibleLanguages}
                                onChange={toggleVisibleLanguage}
                            >
                                Локализация
                            </Checkbox>
                        )}
                        {toggleHeaderAbsolute && (
                            <Checkbox
                                checked={headerAbsolute}
                                style={{ marginLeft: '16px' }}
                                onChange={toggleHeaderAbsolute}
                                {...getTestId('absolute')}
                            >
                                Фиксировать Header
                            </Checkbox>
                        )}
                    </div>
                )}
                <Header
                    {...(avatarUrl
                        ? {
                              avatar: () => (
                                  <div
                                      style={{
                                          backgroundImage: `url(${avatarUrl})`,
                                      }}
                                      className={cx('avatar')}
                                  />
                              ),
                          }
                        : {})}
                    ProfileInfo={
                        <ProfileInfo setOpenUserInfo={setOpenUserInfo} />
                    }
                    language={language}
                    languages={languages}
                    linkBack="/"
                    linkHome="https://www.s7.ru/ru/"
                    login={isLogin}
                    loginTitle={loginTitle}
                    oneWorldLink={false}
                    openUserInfo={openUserInfo}
                    setOpenUserInfo={setOpenUserInfo}
                    togglerProps={getTestId('avatar')}
                    showMobileAddons
                    withMenu
                    onChangeLanguage={onChangeLanguage}
                    onLoginClick={openLogin}
                />
            </div>
            {openLogin && <LoginWithModal />}
        </div>
    );
};

HeaderComponent.propTypes = {
    avatarUrl: string,
    getTestId: func,
    hasActiveSubscription: bool,
    headerAbsolute: bool,
    isLogin: bool,
    isModalOpen: bool,
    isVisibleLanguages: bool,
    language: string,
    languages: arrayOf(string),
    loginTitle: node,
    onChangeLanguage: func.isRequired,
    openLogin: func,
    openUserInfo: bool,
    setOpenUserInfo: func,
    toggleHeaderAbsolute: func,
    toggleVisibleLanguage: func,
};

export default React.memo(HeaderComponent);
