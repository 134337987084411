import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { string } from 'prop-types';
import { compose } from 'ramda';
import { Dictionary } from 'shared';
import { SkeletonLoaderProfileHeader } from 'ui-kit/atoms/SkeletonLoader';

import ProfileHeader from '../../components/ProfileHeader';
import Loadable from '../../decorators/Loadable';
import {
    isError as isErrorLanguages,
    isLoading as isLoadingLanguages,
} from '../../store/modules/languages';
import {
    getAchievements,
    getAvatar,
    getMedias,
    getName,
    getStatistics,
} from '../../store/modules/publicProfile';
import { dictKeys, getTestIdGetter } from '../../utils/testIds';

const getTestId = getTestIdGetter(dictKeys.HEADER);

const PublicProfileHeaderContainer = () => {
    const {
        query: { profileId },
    } = useRouter();
    const achievements = useSelector(getAchievements);
    const statistics = useSelector(getStatistics);
    const medias = useSelector(getMedias);

    const imgURL = useSelector(getAvatar);
    const name = useSelector(getName);

    return (
        <ProfileHeader
            tabs={[
                {
                    disabled: !statistics,
                    href: `/public/${profileId}#statistics`,
                    id: 'statistics',
                    title: <Dictionary text="com.header.tabs.statistics" />,
                },
                {
                    disabled: !achievements.length,
                    href: `/public/${profileId}#achievements`,
                    id: 'achievements',
                    title: <Dictionary text="com.header.tabs.achievements" />,
                },
                ...[
                    {
                        disabled: !medias,
                        href: `/public/${profileId}#photos`,
                        id: 'photos',
                        title: <Dictionary text="com.header.tabs.photos" />,
                    },
                ],
            ]}
            isPublic
            {...{
                getTestId,
                imgURL,
                name,
            }}
        />
    );
};

PublicProfileHeaderContainer.propTypes = {
    tab: string,
};

export default compose(
    Loadable({
        isError: state =>
            !(!state.publicProfile.isError && !isErrorLanguages(state)),
        isLoading: state =>
            !(state.publicProfile.isInitialized && !isLoadingLanguages(state)),
        Loader: <SkeletonLoaderProfileHeader isPublic />,
    }),
    React.memo,
)(PublicProfileHeaderContainer);
