import React from 'react';
import { compose } from 'ramda';
import SkeletonLoaderBreadcrumbs from 'ui-kit/atoms/SkeletonLoaderBreadcrumbs';

import Breadcrumbs from '../../components/Breadcrumbs';
import Loadable from '../../decorators/Loadable';
import {
    isError as isErrorLanguages,
    isLoading as isLoadingLanguages,
} from '../../store/modules/languages';

export default compose(
    Loadable({
        isError: state => isErrorLanguages(state),
        isLoading: state =>
            !(!state.profile.isLoading && !isLoadingLanguages(state)),
        Loader: <SkeletonLoaderBreadcrumbs />,
    }),
)(Breadcrumbs);
