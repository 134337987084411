import React, { PureComponent } from 'react';
import { bool, func, number, oneOf, string } from 'prop-types';
import { omit } from 'ramda';

import cx from './IconButton.sss';

import Icon from '../../../components/Icon';
import GlowPoint from '../GlowPoint';

export default class IconButton extends PureComponent {
    static propTypes = {
        align: oneOf(['middle']),
        glow: bool,
        glowTheme: oneOf(['', 'red']),
        href: string,
        icon: string,
        iconHeight: number,
        iconWidth: number,
        onClick: func,
        size: oneOf(['large', 'big', 'small']),
        theme: oneOf([
            'white',
            'green',
            'gray',
            'greenCircle',
            'greenDesktopCircle',
            'greenRound',
        ]),
        type: oneOf(['button', 'submit']),
    };

    static defaultProps = {
        iconHeight: 20,
        iconWidth: 20,
        onClick: () => {},
        theme: 'gray',
        type: 'button',
    };

    renderIcon() {
        const { icon, iconWidth, iconHeight } = this.props;

        return <Icon height={iconHeight} icon={icon} width={iconWidth} />;
    }

    renderInner() {
        const { glow, glowTheme } = this.props;

        return (
            <span className={cx('inner')}>
                {glow ? (
                    <GlowPoint theme={glowTheme}>{this.renderIcon()}</GlowPoint>
                ) : (
                    this.renderIcon()
                )}
            </span>
        );
    }

    render() {
        const {
            href,
            type,
            onClick,
            theme,
            size,
            align,
            iconWidth,
            iconHeight,
            ...props
        } = this.props;
        const Tag = href ? 'a' : 'button';

        return (
            <Tag
                className={cx('IconButton', {
                    [`theme_${theme}`]: theme,
                    [`size_${size}`]: size,
                    [`align_${align}`]: align,
                })}
                href={href}
                type={href ? null : type}
                onClick={href ? null : onClick}
                {...omit(
                    ['iconWidth', 'iconHeight', 'glow', 'glowTheme'],
                    props,
                )}
            >
                {this.renderInner()}
            </Tag>
        );
    }
}
