import { createStyles } from '@s7/ui';

export const useAvatarStyles = createStyles(({ invariants }) => {
    return {
        wrapper: {
            '& svg': {
                color: '#A1BFA5',
            },
            backgroundColor: invariants.white[100],
            borderRadius: '50%',
            padding: '8px',
        },
    };
});
