import React from 'react';
import { LabelProps } from 'src/shared/types/label';
import { Label } from 'src/shared/ui/Label';

import { LogotypesPlusIcon } from '@s7/icons';

export const YandexLabel = ({ active, className }: LabelProps): JSX.Element => {
    return (
        <Label
            icon={
                <LogotypesPlusIcon
                    sx={{
                        width: 'inherit',
                        height: 'inherit',
                    }}
                />
            }
            active={active}
            className={className}
        />
    );
};
