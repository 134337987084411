import React, { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { connect, useDispatch } from 'react-redux';
import LoginComponent from 'components/Login';
import { useAppContext } from 'contexts';
import Loadable from 'decorators/Loadable';
import { useRouter } from 'next/router';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import { compose } from 'ramda';
import { SessionService } from 'services';
import { Dictionary } from 'shared';
import { ONE_SIGNAL_ID } from 'src/shared/config';
import {
    closeLoginModal,
    getIsLoginModalOpen,
    getSocial,
    login,
    loginFailure,
} from 'store/modules/authentication';
import {
    getLocale,
    isError as isErrorLanguages,
    isLoading as isLoadingLanguages,
} from 'store/modules/languages';
import { getIsSocialProfile } from 'store/modules/profile';
import FailedToLoad from 'ui-kit/atoms/FailedToLoad';
import SkeletonLoaderAuthorization from 'ui-kit/atoms/SkeletonLoaderAuthorization';

const PASSWORD_RECOVERY_URL = '/processingConfirmation';

const mapStateToProps = state => ({
    isOpen: getIsLoginModalOpen(state),
    locale: getLocale(state),
    socialState: getSocial(state),
    temporaryResource: getIsSocialProfile(state)
        ? SessionService.getResourceId()
        : undefined,
});

const mapDispatchToProps = {
    onClose: closeLoginModal,
    onLoginFailure: loginFailure,
    onLoginSuccess: login,
};

const Login = props => {
    const {
        business,
        isBusiness,
        isModal,
        isOpen,
        locale,
        loginBottomText,
        loginButtonText,
        loginHeaderText,
        onClose,
        onLoginFailure,
        onLoginSuccess,
        redirect,
        registrationUrl,
        showSuccessRecoverPopupOnMount,
        socialLogin,
        socialOneTap,
        socialState,
        temporaryResource,
        onConfirm,
    } = props;
    const router = useRouter();
    const dispatch = useDispatch();
    const { inApp } = useAppContext();
    const [oneSignalUserId, setOneSignalUserId] = useState('');

    const handleLoginSuccess = React.useCallback(
        data => {
            if (onConfirm) {
                onConfirm();
            }

            onLoginSuccess({ ...data, business, isBusiness, redirect });
        },
        [business, isBusiness, onConfirm, onLoginSuccess, redirect],
    );

    const recoveryHref = React.useCallback(() => {
        dispatch(closeLoginModal());
        router.push({
            pathname: PASSWORD_RECOVERY_URL,
            query: isBusiness
                ? { ...router.query, from: '/b2b' }
                : router.query,
        });
    }, [dispatch, isBusiness, router]);

    useEffect(() => {
        if (ONE_SIGNAL_ID) {
            OneSignal.init({ appId: ONE_SIGNAL_ID }).then(() => {
                const userId = OneSignal.User.PushSubscription.id;
                setOneSignalUserId(userId);
            });
        }
    }, []);

    return (
        <LoginComponent
            registrationHref={
                redirect
                    ? `${registrationUrl}?redirect=${redirect}`
                    : registrationUrl
            }
            isB2B={isBusiness}
            isModal={isModal}
            isOpen={isOpen}
            language={locale}
            login={business ? business.email : undefined}
            loginBottomText={loginBottomText}
            loginButtonText={loginButtonText}
            loginHeaderText={loginHeaderText}
            oneSignalUserId={oneSignalUserId}
            recoveryHref={recoveryHref}
            showSuccessRecoverPopupOnMount={showSuccessRecoverPopupOnMount}
            socialApple={socialLogin && !inApp}
            socialFacebook={socialLogin && !inApp}
            socialGoogle={socialLogin && !inApp}
            socialLogin={socialLogin && !inApp}
            socialOneTap={socialOneTap && !inApp}
            socialState={socialState}
            socialTinkoff={socialLogin && !inApp}
            socialVk={socialLogin && !inApp}
            temporaryResource={temporaryResource}
            onClose={onClose}
            onLoginError={onLoginFailure}
            onLoginSuccess={handleLoginSuccess}
        />
    );
};

Login.propTypes = {
    business: shape({
        company: string,
        email: string,
        invitation: string,
        isConfirmed: bool,
    }),
    isBusiness: bool,
    isModal: bool,
    isOpen: bool,
    locale: string,
    loginBottomText: node,
    loginButtonText: node,
    loginHeaderText: node,
    onClose: func,
    onConfirm: func,
    onLoginFailure: func,
    onLoginSuccess: func,
    redirect: string,
    registrationUrl: string,
    showSuccessRecoverPopupOnMount: bool,
    socialLogin: bool,
    socialOneTap: bool,
    socialState: shape({
        error: shape({
            code: string,
            details: arrayOf(
                shape({
                    code: string,
                    value: string,
                }),
            ),
            message: string,
        }),
        token: string,
        type: string,
    }),
    submitButtonText: node,
    temporaryResource: string,
};

Login.defaultProps = {
    isModal: false,
    loginButtonText: <Dictionary text="com.authorization.button.login" />,
    registrationUrl: '/registration',
    socialLogin: true,
};

const LoginSkeleton = <SkeletonLoaderAuthorization />;
const LoginModalSkeleton = <SkeletonLoaderAuthorization isModal />;

export default compose(
    Loadable({
        isError: state => isErrorLanguages(state),
        isLoading: state => {
            const loadedLang = state.languages.loaded;

            return !loadedLang.global || isLoadingLanguages(state);
        },
        // eslint-disable-next-line react/prop-types
        Loader: (state, props) =>
            props.isModal ? LoginModalSkeleton : LoginSkeleton,
        Rebooter: <FailedToLoad />,
    }),
    connect(mapStateToProps, mapDispatchToProps),
    React.memo,
)(Login);
