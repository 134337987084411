import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Login from 'containers/Login';
import { useAnalyticsContext } from 'contexts';
import {
    closeLoginModal,
    getIsLoginModalOpen,
} from 'store/modules/authentication';

const LoginWithModal = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(getIsLoginModalOpen);
    const { sendEvent } = useAnalyticsContext();

    const onClose = useCallback(() => {
        dispatch(closeLoginModal());
    }, [dispatch]);

    useEffect(() => {
        if (isOpen) {
            sendEvent({
                event: 'view_screen',
                flow: 'login',
                step: 'init',
                ux_ui: 'popup',
            });
        }
    }, [isOpen, sendEvent]);

    return (
        <div>
            <Login isOpen={isOpen} isModal onCloseModal={onClose} />
        </div>
    );
};

export default React.memo(LoginWithModal);
