import React from 'react';
import { useSelector } from 'react-redux';
import { getRawProfile } from 'store/modules/profile';
import { isNotIE11 } from 'utils';

const ChatComponent = isNotIE11 ? (
    React.lazy(() => import('components/Chat'))
) : (
    <></>
);

const Chat = props => {
    const profile = useSelector(getRawProfile);

    return <ChatComponent {...props} profile={profile} />;
};

export default React.memo(Chat);
