import { LabelType } from 'src/shared/types/label';

type OpenModalEvent = {
    // eslint-disable-next-line camelcase
    event_name: string;
    flow: string;
    parameter?: string;
};

export const getOpenModalEvent = (labels: LabelType[]) => {
    const event: OpenModalEvent = {
        event_name: 'view_screen',
        flow: 'your_options',
    };

    labels.forEach(label => {
        if (label.active) {
            event.parameter = event.parameter
                ? `${event.parameter},${label.name}`
                : `options:${label.name}`;
        }
    });

    return event;
};
