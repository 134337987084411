import React from 'react';
import { COOKIES } from 'consts';
import { ALERT_TYPE } from 'consts/alerts';
import { sanitize } from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
import { cookieService } from 'services';

import { Link } from '@s7/ui-kit';

const linkView = {
    [ALERT_TYPE.WARNING]: 'warning',
    [ALERT_TYPE.DEFAULT]: 'info',
};

export const transformAlertsData = (alerts = []) => {
    if (alerts.length < 0) {
        return [];
    }

    const lang = cookieService.get(COOKIES.LANG);

    return alerts.map(alert => {
        const currentTextLang = alert.alertText[lang] || alert.alertText?.ru;
        const parseOptions = {
            replace: domNode => {
                /**
                 * Так как TechnicalMessage отображает текст в теге <p>,
                 * необходимо удалить все блочны теги,
                 * чтобы верстка была валидной
                 */
                if (domNode.name && ['div', 'p'].includes(domNode.name)) {
                    return domToReact(domNode.children, parseOptions);
                }

                if (domNode.name && domNode.attribs && domNode.name === 'a') {
                    return (
                        <Link
                            href={domNode.attribs.href}
                            view={linkView[alert?.type]}
                        >
                            {domToReact(domNode.children, parseOptions)}
                        </Link>
                    );
                }

                return domNode;
            },
        };

        return {
            content: parse(sanitize(currentTextLang), parseOptions),
            hideCloseButton: alert?.hideCloseButton,
            id: alert?.id,
            type: alert?.type.toLowerCase(),
        };
    });
};
