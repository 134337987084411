import React, { PureComponent } from 'react';
import { bool, func, string } from 'prop-types';
import { Dictionary } from 'shared';

import cx from './AddAppleWallet.sss';
import Apple from './AppleWallet.svg';

export default class AddAppleWallet extends PureComponent {
    static propTypes = {
        alt: string,
        onClick: func,
        small: bool,
    };

    render() {
        // const { onClick, small } = this.props;

        return null;
        //
        // return (
        //     <div
        //         className={cx('AddWalletWrapper', {
        //             small,
        //         })}
        //     >
        //         <Apple className={cx('image')} />
        //         {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        //         <a className={cx('link')} href="/api/ffppay" onClick={onClick}>
        //             <Dictionary text="com.title.add-to-apple-wallet" />
        //         </a>
        //     </div>
        // );
    }
}
